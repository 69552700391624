import { combineReducers } from 'redux';
import preinfoReducer from './preinfoReducer';
import questionsReducer from './questionsReducer';
import formReducer from './formReducer';

export default combineReducers({
  preinfo: preinfoReducer,
  questions: questionsReducer,
  form: formReducer,
});
