import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Layout.scss';

const Layout = ({ children, title }) => {
  return (
    <div className='container'>
      <Header title={title} />
      {children}
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  title: 'Muistisairaan asumistilanteen arviointi',
};

export default Layout;
