import React from 'react';
import InstructionsLayout from './InstructionsLayout';
import './Arrows.scss';

const Instruction2 = () => {
  return (
    <InstructionsLayout title='Mitä Muistava-työkaluja voin hyödyntää, jos...'>
      <h1
        style={{ fontSize: '1.5rem', lineHeight: '1.9rem', fontWeight: 'bold' }}
      >
        Mitä Muistava-työkaluja voin hyödyntää, jos...
      </h1>

      <h4 style={{ paddingTop: '5px', paddingBottom: '5px' }}>
        haluan suunnitella tulevaa asumistani itsenäisesti tai yhdessä
        läheisteni kanssa
      </h4>
      <ul>
        <li className='arrow'>
          Lomake oman tahdon ilmaisuun soveltuu asumistoiveiden kirjaamiseen
          <br />
          <br />
          Kuvakortit soveltuvat esim. perheen sisäisen keskustelun tueksi
        </li>
        <ul>
          <li className='arrow'>
            Lisätietoa ja tukea muistisairaan asumisen suunnitteluun:
            <br />
            <br />
            <a href='https://www.muistava.fi'>www.muistava.fi</a>
          </li>
        </ul>
      </ul>

      <h4>
        haluan suunnitella tulevaa asumistani itsenäisesti tai yhdessä
        läheisteni kanssa
      </h4>
      <ul>
        <li className='arrow'>
          Muistava-kuvakortit tukevat asumistilanteesta keskustelua
          muistisairaan ja tämän läheisen kanssa
          <br />
          <br />
          Asumistilanteen arviointityökalu auttaa muistisairaan asumistilanteen
          kokonaisuuden kartoittamisessa
        </li>
        <ul>
          <li className='arrow'>
            Nykyinen asumistilanne vastaa muistisairaan tarpeita.
          </li>
          <ul>
            <li className='arrow'>
              Asumistilannetta on hyvä seurata ja kartoittaa tarvittaessa
              uudelleen.
            </li>
          </ul>
          <li className='arrow'>
            Nykyinen asumistilanne ei vastaa kaikilta osin muistisairaan
            tarpeisiin.
          </li>
          <ul>
            <li className='arrow'>
              Asumistilanteesta on syytä keskustella. Apua ja tukea saa
              muistiyhdistyksistä ja sosiaali- ja terveyden-huollon
              ammattilaisilta
            </li>
          </ul>
        </ul>
      </ul>
    </InstructionsLayout>
  );
};

export default Instruction2;
