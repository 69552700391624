import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom';
// import { persistStore } from 'redux-persist';
// import store from './../../store';
// import { purgeStoredState } from 'redux-persist';
// import storageSession from 'redux-persist/lib/storage/session';
import '../Base.scss';
import './Header.scss';

import muistavaLogo from './muistava-logo-300.png';

const Header = ({
  preInfo: { userIsProfessional, patientLivesAlone },
  title,
}) => {
  const resetPreinfoLink = userIsProfessional !== undefined &&
    patientLivesAlone !== undefined && (
      <li className='reset--link'>
        <Link to='/preinfo' className='info preinfo'>
          Muuta esitietoja
        </Link>
      </li>
    );
  // const clearAll = () => {
  // };

  const location = useLocation();

  const startClass =
    location.pathname === '/'
      ? 'start'
      : location.pathname.substr(1).replace('/', '-');
  return (
    <>
      <Helmet>
        <title>{title} | Hoitotyön tutkimussäätiö & Muistiliitto</title>
      </Helmet>
      <div className='section--wrapper header--wrapper'>
        <div className={`header ${startClass}`}>
          <div className='logo--holder'>
            <Link to='/'>
              <img src={muistavaLogo} alt='Muistava' />
            </Link>
          </div>
          {title !== '' && (
            <div className='site--title'>
              <h1>{title}</h1>
            </div>
          )}

          <div className='header--navi'>
            <nav className='page-nav'>
              {/* eslint-disable jsx-a11y/label-has-associated-control */}
              {/* eslint-disable jsx-a11y/label-has-for */}
              <label htmlFor='hamburger'>&#9776;</label>
              <input type='checkbox' id='hamburger' />

              <ul className={location.pathname === '/' ? 'start' : ''}>
                {location.pathname === '/preinfo' &&
                  (userIsProfessional !== undefined ||
                    patientLivesAlone !== undefined) && (
                    <li className='reset--link'>
                      <a
                        href='/preinfo?reset'
                        className='header--div--link'
                        onClick={(e) => {
                          if (
                            /* eslint-disable no-alert */
                            !window.confirm(
                              'Jatkamalla tyhjennät kaikki antamasi vastaukset ja aloitat työkalun täyttämisen alusta. Haluatko jatkaa?'
                            )
                          )
                            e.preventDefault();
                        }}
                      >
                        Tyhjennä valinnat
                      </a>
                    </li>
                  )}
                {location.pathname !== '/' &&
                  location.pathname !== '/preinfo' &&
                  location.pathname !== '/serviceinfo' &&
                  location.pathname !== '/tips' &&
                  resetPreinfoLink}
                {location.pathname !== '/serviceinfo' && (
                  <li>
                    <Link to='/serviceinfo' className='info'>
                      Tietoa palvelusta
                    </Link>
                  </li>
                )}
                {location.pathname !== '/tips' && (
                  <li>
                    <Link to='/tips' className='info'>
                      Vinkkejä käyttöönottoon
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  preInfo: state.preinfo,
});

export default connect(mapStateToProps, null)(Header);
