import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import FooterNavi from '../../components/FooterNavi/FooterNavi';
import { clearCurrentChoice } from '../../actions/questionActions';

import './Main.scss';

const useStyles = makeStyles(() => ({
  category: {
    textAlign: 'center',
    border: 0,
    height: 200,
    backgroundColor: '#DAEBF3',
    color: '#10709b',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  red: {
    backgroundColor: '#feeae8',
  },
  yellow: {
    backgroundColor: '#fff2d1',
  },
  blue: {
    backgroundColor: '#C9EFF6',
  },
  categoryText: {
    color: '#10709b',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: '1em',
    lineHeight: '1.2em',
    fontWeight: 'bold',
  },
}));

const Main = ({
  questions: { data },
  preInfo: { userIsProfessional, patientLivesAlone },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (userIsProfessional === undefined && patientLivesAlone === undefined) {
      history.push('/preinfo');
    }
    dispatch(clearCurrentChoice());
  }, [history, userIsProfessional, patientLivesAlone, dispatch]);

  const classes = useStyles();

  const categoryBackground = (slug) => {
    const categoryData = data.find((dat) => dat.slug === slug);
    const negativesExist = categoryData.items.find(
      (dat) => dat.currentChoiceType === 'negative'
    );
    if (negativesExist) {
      return classes.red;
    }
    const neutralsExist = categoryData.items.find(
      (dat) => dat.currentChoiceType === 'neutral'
    );
    if (neutralsExist) {
      return classes.yellow;
    }
    const allPositives = categoryData.items.filter(
      (dat) => dat.currentChoiceType === 'positive'
    );
    if (allPositives.length === categoryData.items.length) {
      return classes.blue;
    }
    return '';
  };

  const isReportVisible = (dat) => {
    const mappedData = dat.map(
      (ob) =>
        ob.items.some((element) =>
          Object.prototype.hasOwnProperty.call(element, 'currentChoiceType')
        ) && true
    );

    if (mappedData.includes(true)) {
      return true;
    }
    return false;
  };

  const appData = Object.keys(data);

  const categoryLengthClass = appData.length === 7 ? 'c7' : 'c9';
  return (
    <Layout title='Osa-alueet - Muistisairaan asumistilanteen arviointi'>
      <Section variant='blue' topMargin='0'>
        <Section variant='blue' maxWidth='md' topMargin='0'>
          <div style={{ paddingBottom: 30 }}>
            <a href='/'>Etusivu</a> {'>'} Muistisairaan asumistilanteen
            arviointi
          </div>
          <Section marginBottom='sm'>
            <span className='main--guide'>
              {isReportVisible(data)
                ? 'Näet nyt eri osa-alueita koskevat kriittisyysarviot värikoodattuina.'
                : 'Valitse osa-alue aloittaaksesi.'}
            </span>
            <p>
              Voit käydä läpi kaikki osa-alueet tai valita vain ne, joiden
              tarkasteluun koet olevan eniten tarvetta kartoittaessasi
              muistisairaan asumistilannetta ja sen puheeksi oton tarvetta. Koko
              työkalun kaikkien osa-alueiden täyttäminen vie aikaa noin tunnin
              verran.
            </p>

            <p>
              Osa-alueissa sinulle esitetään kysymyksiä valmiine
              vastausvaihtoehtoineen. Valitse vastausvaihtoehdoista parhaiten
              tilannetta kuvaava vaihtoehto. Lue tarkemmat käyttöohjeet{' '}
              <a
                href='/Kayttoohje_Asumistilanteen-arviointityokalu.pdf'
                target='_blank'
              >
                täältä
              </a>
              .
            </p>
          </Section>

          <div className={`categories ${categoryLengthClass}`}>
            {appData.map((category) => (
              <Card
                className={`${classes.category} ${categoryBackground(
                  data[category].slug
                )} `}
                variant='outlined'
                key={data[category].slug}
                onClick={() =>
                  history.push(`/questions/${data[category].slug}`)
                }
              >
                <CardContent>
                  <img
                    src={`icons/${data[category].slug}.svg`}
                    alt={data[category].title}
                  />
                  <Typography className={classes.categoryText} gutterBottom>
                    {data[category].title}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
          {isReportVisible(data) && (
            <Button
              fullWidth
              className='full'
              variant='outlined'
              onClick={() => history.push('/report')}
            >
              Katso raportti
            </Button>
          )}
          <FooterNavi backlink='/' backlinkText='Takaisin alkuun' />
        </Section>
      </Section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  questions: state.questions,
  preInfo: state.preinfo,
});

export default connect(mapStateToProps, { clearCurrentChoice })(Main);
