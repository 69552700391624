import React from 'react';
import InstructionsLayout from './InstructionsLayout';
import './Arrows.scss';

const Instruction3 = () => {
  return (
    <InstructionsLayout
      title={
        <>
          <br />
          Miten voin hyödyntää Muistava-työkaluja muistisairautta sairastavan
          asumisen suunnittelussa?
        </>
      }
    >
      <h1>
        Miten voin hyödyntää Muistava-työkaluja muistisairautta sairastavan
        asumisen suunnittelussa?
        <span
          style={{
            fontSize: '1.5rem',
            lineHeight: '1.9rem',
            fontWeight: 'bold',
          }}
        >
          <br />
          -vinkkejä eri elämäntilanteisiin
        </span>
      </h1>

      <h4>SOTE-TOIMIJOIDEN VASTAANOTOILLA</h4>

      <ul>
        <li>Lomake oman tahdon ilmaisuun</li>
        <li>Kuvakortit</li>

        <li className='arrow'>
          Asian normaaliuden, varhaisen keskustelun ja varautumisen hyötyjen
          korostaminen sekä muutokseen liittyvän kriisin huomioiminen
        </li>
      </ul>

      <h4>ENSITIETOKURSSEILLA</h4>

      <ul>
        <li>Lomake oman tahdon ilmaisuun</li>
        <li>Kuvakortit</li>
        <li className='arrow'>
          Asumisen suunnittelun normaaliuden, varhaisen keskustelun ja
          varautumisen hyötyjen korostaminen sekä muutokseen liittyvän kriisin
          huomioiminen
        </li>
      </ul>

      <h4>MUISTILIITON TUKIPUHELIMESSA JA MUISTIYHDISTYSTEN CHATEISSA</h4>
      <ul>
        <li className='arrow'>
          Asumistilanteen arviointi -työkalun hyödyntäminen asumistilanteen
          puheeksi otossa ja soittajan tilanteen hahmottamisessa
        </li>
      </ul>

      <h4>VERTAIS- JA VIRKISTYSKURSSEILLA</h4>
      <ul>
        <li>Kuvakortit</li>
        <li className='arrow'>
          Asian nostaminen keskusteluun yksi osa-alue kerrallaan kuvakorttien
          avulla
        </li>
      </ul>

      <h4>SOSIAALI- JA TERVEYDENHUOLLOSSA, ESIM. KOTIHOITO</h4>

      <ul>
        <li>Asumistilanteen arviointityökalu</li>
        <li className='arrow'>
          Asumistilanteen arviointityökalunhyödyntäminen asumistilanteen
          hahmottamisessa ja puheeksi otossa
        </li>
      </ul>

      <h4>YKSILÖTAPAAMISISSA</h4>

      <ul>
        <li>Asumistilanteen arviointityökalu</li>
        <li>Kuvakortit</li>
        <li className='arrow'>
          Muistava-työkalujen täyttäminen tarvittaessa yhdessä
          muistisairaan/omaisen kanssa{' '}
        </li>
        <li className='arrow' style={{ marginTop: 0 }}>
          Huolenaiheista keskusteleminen Muistava-työkaluja hyödyntämällä, tuen
          tarjoaminen esille nouseviin vaikeisiin tunteisiin
        </li>
      </ul>

      <h4>PALVELUASUMISESSA</h4>

      <ul>
        <li>Lomake oman tahdon ilmaisuun</li>
        <li className='arrow'>
          Muistisairaan toiveiden kuunteleminen ja toteuttaminen
        </li>
      </ul>
    </InstructionsLayout>
  );
};

export default Instruction3;
