import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import FooterNavi from '../../components/FooterNavi/FooterNavi';
import { questionBackgroundColor } from '../../components/functions';

import './Report.scss';

import printerLogo from './printer.png';

const Report = ({
  questions: { data },
  preInfo: { userIsProfessional, patientLivesAlone },
}) => {
  const history = useHistory();

  useEffect(() => {
    if (userIsProfessional === undefined && patientLivesAlone === undefined) {
      history.push('/preinfo');
    }
  }, [history, userIsProfessional, patientLivesAlone]);

  const [negatives, setNegatives] = useState(0);
  const [neutrals, setNeutrals] = useState(0);

  const getCategoryReport = (category) => {
    if (!category.items) return '';

    let negs = false;
    let neuts = false;
    const categoryItems = category.items.map((item) => {
      const returnSection = (
        <Section
          variant={questionBackgroundColor(item.currentChoiceType)}
          marginBottom='sm'
          key={item.currentChoiceAnswer}
        >
          {item.currentChoiceType === 'negative' && (
            <div className='report--critical'>Kriittinen</div>
          )}
          <div className='report--question--category'>{item.category}</div>
          <div className='report--question'>{item.question}</div>
          <div className='report--answer'>{item.currentChoiceAnswer}</div>
        </Section>
      );

      let returnable = '';
      if (item.currentChoiceType === 'negative') {
        negs = true;
        returnable = returnSection;
      } else if (item.currentChoiceType === 'neutral') {
        neuts = true;
        returnable = returnSection;
      }
      return returnable;
    });
    if (negs && !negatives) setNegatives(1);
    if (neuts && !neutrals) setNeutrals(1);

    const categoriesWithNoItems = categoryItems.filter((el) => el !== '');
    return (
      <>
        {categoriesWithNoItems.length ? <h2>{category.title}</h2> : ''}
        {categoryItems}
      </>
    );
  };

  const needsAttention = (dat) => {
    const mappedData = dat.map(
      (ob) =>
        ob.items.some(
          (element) =>
            Object.prototype.hasOwnProperty.call(
              element,
              'currentChoiceType'
            ) &&
            (element.currentChoiceType === 'negative' ||
              element.currentChoiceType === 'neutral')
        ) && true
    );

    if (mappedData.includes(true)) {
      return true;
    }
    return false;
  };

  return (
    <Layout title='Raportti - Muistisairaan asumistilanteen arviointi'>
      <Section variant='blue' topMargin='0'>
        <Section variant='blue' maxWidth='md' topMargin='0'>
          <div style={{ paddingBottom: 30 }}>
            <a href='/'>Etusivu</a> {'>'}{' '}
            <a href='/main'>Muistisairaan asumistilanteen arviointi</a> {'>'}
            Raportti
          </div>

          <Section marginBottom='sm'>
            <div className='report--header'>
              <h2>Raportti</h2>
              <div className='no-print'>
                <div
                  onClick={() => window.print()}
                  onKeyPress={() => window.print()}
                  role='button'
                  tabIndex={0}
                >
                  Tulosta
                </div>
                <input
                  className='print--image'
                  type='image'
                  src={printerLogo}
                  alt='Tulosta'
                  onClick={() => window.print()}
                  onKeyPress={() => window.print()}
                />
              </div>
            </div>
          </Section>
          <Section marginBottom='sm'>
            <div className='notice--section'>
              <h2>Taustatiedot</h2>
              Täyttäjä on{' '}
              {userIsProfessional
                ? 'sosiaali- ja terveydenhuollon tai muu ammattilainen'
                : 'muistisairautta sairastavan henkilön omainen tai läheinen'}
              . Muistisairas{' '}
              {patientLivesAlone
                ? ' asuu yksin.'
                : ' asuu yhdessä omaisen/läheisen kanssa tai muualla asuva läheinen (esim. omainen, naapuri) huolehtii hänestä päivittäin.'}
            </div>
          </Section>
          {needsAttention(data) && (
            <Section marginBottom='sm'>
              <div className='notice--section'>
                <h2>Vaatii huomiota</h2>
                Seuraavat kohdat vaativat huomiota
              </div>
            </Section>
          )}
          {needsAttention && (
            <div className='needsattention--section'>
              {data.map((category) => getCategoryReport(category))}
            </div>
          )}
          <Section marginBottom='sm'>
            <div className='notice--section'>
              <h2>Tulosten tulkinta</h2>

              {!negatives && !neutrals ? (
                <p>
                  {!userIsProfessional
                    ? 'Vastausten perusteella muistisairautta sairastavan läheisesi nykyinen asuinmuoto ja asumisympäristö näyttää vastaavan hänen tarpeitaan. Kunkin muistisairaan tilanne tulee kuitenkin arvioida aina yksilöllisesti kokonaisuutena. Mikäli läheisesi tilanteessa ilmenee jotakin muuta huolta herättävää, tai teillä on tarve keskustella hänen asumistilanteestaan, on asia syytä ottaa puheeksi. Asumistilannetta ja sen mahdollisia muutoksia on hyvä myös seurata säännöllisin väliajoin.'
                    : 'Vastausten perusteella muistisairautta sairastavan henkilön nykyinen asuinmuoto ja asumisympäristö näyttää vastaavan hänen tarpeitaan. Kunkin muistisairaan tilanne tulee kuitenkin arvioida aina yksilöllisesti kokonaisuutena. Mikäli muistisairaan tai läheisen tilanteessa ilmenee jotakin muuta huolta herättävää, tai heillä on tarve keskustella asumistilanteestaan, on asia syytä ottaa puheeksi. Asumistilannetta ja sen mahdollisia muutoksia on hyvä myös seurata säännöllisin väliajoin.'}
                </p>
              ) : (
                ''
              )}
              {!negatives && neutrals ? (
                <p>
                  {!userIsProfessional
                    ? 'Vastausten perusteella muistisairautta sairastavan läheisesi nykyinen asumistilanne on syytä ottaa puheeksi ja tarkastella etenkin yllä esitettyjen osa-alueiden näkökulmasta. Keskustelkaa, voisiko tilannetta parantaa erilaisin tukimuodoin tai kodin muutostöin. Kunkin muistisairaan tilanne tulee kuitenkin arvioida aina yksilöllisesti kokonaisuutena. Mikäli läheisesi tilanteessa ilmenee lisäksi jotakin muuta huolta herättävää, tai teillä on tarve keskustella hänen asumistilanteestaan, on asia syytä ottaa myös näistä näkökulmista puheeksi. Asumistilannetta ja sen mahdollisia muutoksia on hyvä seurata myös jatkossa säännöllisin väliajoin.'
                    : 'Vastausten perusteella muistisairautta sairastavan henkilön nykyinen asumistilanne on syytä ottaa puheeksi ja tarkastella etenkin yllä esitettyjen osa-alueiden näkökulmasta. Keskustelkaa, voisiko tilannetta parantaa erilaisin tukimuodoin tai kodin muutostöin. Kunkin muistisairaan tilanne tulee kuitenkin arvioida aina yksilöllisesti kokonaisuutena. Mikäli muistisairaan tai läheisen tilanteessa ilmenee lisäksi jotakin muuta huolta herättävää, tai heillä on tarve keskustella asumistilanteestaan, on asia syytä ottaa myös näistä näkökulmista puheeksi. Asumistilannetta ja sen mahdollisia muutoksia on hyvä seurata myös jatkossa säännöllisin väliajoin.'}
                </p>
              ) : (
                ''
              )}
              {negatives ? (
                <p>
                  {!userIsProfessional
                    ? 'Vastausten perusteella muistisairautta sairastavan läheisesi nykyinen asumistilanne on syytä ottaa välittömästi puheeksi ja arvioida kokonaisuutena, sillä huolta ilmenee yllä esitetyillä osa-alueilla (punaiset: kriittiset; keltaiset: muut huomiota vaativat). Kunkin muistisairaan tilanne tulee kuitenkin arvioida aina yksilöllisesti kokonaisuutena. Mikäli läheisesi tilanteessa ilmenee lisäksi jotakin muuta huolta herättävää, tai teillä on tarve keskustella hänen asumistilanteestaan, on asia syytä ottaa myös näistä näkökulmista puheeksi. Asumistilannetta ja sen mahdollisia muutoksia on hyvä seurata myös jatkossa säännöllisin väliajoin.'
                    : 'Vastausten perusteella muistisairautta sairastavan henkilön nykyinen asumistilanne on syytä ottaa välittömästi puheeksi ja arvioida kokonaisuutena, sillä huolta ilmenee yllä esitetyillä osa-alueilla (punaiset: kriittiset; keltaiset: muut huomiota vaativat). Kunkin muistisairaan tilanne tulee kuitenkin arvioida aina yksilöllisesti kokonaisuutena. Mikäli muistisairaan tai läheisen tilanteessa ilmenee lisäksi jotakin muuta huolta herättävää, tai heillä on tarve keskustella asumistilanteestaan, on asia syytä ottaa myös näistä näkökulmista puheeksi. Asumistilannetta ja sen mahdollisia muutoksia on hyvä seurata myös jatkossa säännöllisin väliajoin.'}
                </p>
              ) : (
                ''
              )}
            </div>
          </Section>
          <FooterNavi backlink='/main' />
        </Section>
      </Section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  questions: state.questions,
  preInfo: state.preinfo,
});

export default connect(mapStateToProps)(Report);
