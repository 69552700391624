import React from 'react';

import Section from '../../components/Section/Section';

import Layout from '../../components/Layout/Layout';
import FooterNavi from '../../components/FooterNavi/FooterNavi';

const InstructionsLayout = ({ title, children }) => {
  return (
    <Layout title=''>
      <Section variant='blue' maxWidth='md' topMargin='0'>
        <div style={{ paddingBottom: 30 }}>
          <a href='/'>Etusivu</a> >{' '}
          <a href='/serviceinfo'>Tietoa Muistava-työkaluista</a> > {title}
        </div>
        <div className='section--wrapper white--wrapper'>{children}</div>
        <FooterNavi />
      </Section>
    </Layout>
  );
};

export default InstructionsLayout;
