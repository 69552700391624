import React from 'react';

import Section from '../../components/Section/Section';

import Layout from '../../components/Layout/Layout';
import FooterNavi from '../../components/FooterNavi/FooterNavi';

const AccessibilityInfo = () => {
  return (
    <Layout title='Muistava.fi-verkkosivuston saavutettavuusseloste'>
      <Section variant='blue' maxWidth='md' topMargin='0'>
        <div style={{ paddingBottom: 30 }}>
          <a href='/'>Etusivu</a> > <a href='/serviceinfo'>Tietoa palvelusta</a>{' '}
          {'>'} Saavutettavuusseloste
        </div>
        <div className='section--wrapper white--wrapper'>
          <p>
            Tämä saavutettavuusseloste koskee palvelua Muistava.fi ja on
            laadittu / päivitetty 21.12.2020. Palvelua koskee laki digitaalisten
            palvelujen tarjoamisesta, jossa edellytetään, että julkisten
            verkkopalvelujen on oltava saavutettavia.
            <br />
            <br />
            Palvelun saavutettavuuden on arvioinut ulkopuolinen
            asiantuntijaorganisaatio.
          </p>

          <h2>Digipalvelun saavutettavuuden tila</h2>
          <p>
            Palvelu täyttää saavutettavuusvaatimukset osittain (WCAG-kriteeristö
            2.1, A- ja AA-taso).
          </p>
          <h2>Ei-saavutettava sisältö</h2>
          <p>Verkkosivusto ei ole vielä kaikilta osin vaatimusten mukainen</p>

          <ul>
            Sivustolla on sisältöä, joka ei ole kaikilta osin saavutettavaa:
            <li>
              PDF-tiedostot koskien kuvakortteja. Tiedostojen ohjelmallisissa
              merkinnöissä on puutteita, joiden vuoksi ne ovat vaikeasti
              tulkittavissa ruudunlukijalla. (WCAG 1.3.1)
            </li>
          </ul>

          <h2>
            Huomasitko saavutettavuuspuutteen digipalvelussamme? Kerro se meille
            ja teemme parhaamme puutteen korjaamiseksi
          </h2>
          <p>
            Verkkolomakkeella
            <br />
            <a
              href='https://www.hotus.fi/ota-yhteytta/'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://www.hotus.fi/ota-yhteytta/
            </a>
            <br />
            <br />
            Sähköpostilla
            <br />
            <a href='mailto:hotus@hotus.fi'>hotus@hotus.fi</a>
          </p>

          <h2>Valvontaviranomainen</h2>
          <p>
            Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta
            meille eli sivuston ylläpitäjälle. Vastauksessa voi mennä 14 päivää.
            Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta
            lainkaan kahden viikon aikana,{' '}
            <a
              href='https://www.saavutettavuusvaatimukset.fi/oikeutesi/'
              target='_blank'
              rel='noopener noreferrer'
            >
              voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon
            </a>
            . Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti,
            miten ilmoituksen voi tehdä ja miten asia käsitellään.
          </p>
          <h2>Valvontaviranomaisen yhteystiedot</h2>
          <p>
            Etelä-Suomen aluehallintovirasto
            <br />
            Saavutettavuuden valvonnan yksikkö
            <br />
            <a
              href='https://www.saavutettavuusvaatimukset.fi'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.saavutettavuusvaatimukset.fi
            </a>
            <br />
            <a href='mailto:saavutettavuus@avi.fi'>saavutettavuus@avi.fi</a>
            <br />
            puhelinnumero vaihde 0295 016 000
          </p>

          <h2>Teemme jatkuvasti työtä saavutettavuuden parantamiseksi</h2>

          <h3 style={{ fontWeight: 'normal' }}>
            Olemme sitoutuneet digipalveluiden saavutettavuuden parantamiseen
          </h3>
          <p>
            Hoitotyön tutkimussäätiöllä on tavoitteena saavuttaa digitaalisessa
            saatavuudessa parempi taso ja korjata saavutettavuuskartoituksessa
            ilmenneet puutteet saavutettavuudessa heti, kun se on säätiön
            kannalta mahdollista.
          </p>
        </div>
        <FooterNavi />
      </Section>
    </Layout>
  );
};

export default AccessibilityInfo;
