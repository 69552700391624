import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { setFormField } from '../../actions/formActions';
import Section from '../../components/Section/Section';

import Layout from '../../components/Layout/Layout';
import FormInfo from '../../components/FormInfo/FormInfo';
import FooterNavi from '../../components/FooterNavi/FooterNavi';
import './Form.scss';

const Form = ({
  form: { field1, field2, field3, field4, field5, field6, field7, field8 },
}) => {
  const dispatch = useDispatch();
  const formInfo1 = (
    <ul>
      <li>
        Jotta kotini on myös jatkossa turvallinen, toivon huomiota
        kiinnitettävän seuraaviin asioihin… (esim. kodin vaaranpaikat,
        turvaranneke, automaattiset hälytykset)
      </li>
      <li>
        Toivon, että minulla on käytettävissäni tarvittavat apuvälineet, kuten…
        (esim. kaiteet, tuet, wc-korotukset)
      </li>
      <li>
        Toivon saavani apua tarvitsemiini päivittäisiin toimiin
        seuraavanlaisesti….
      </li>
      <li>Toivon saavani apua hätätilanteessa seuraavanlaisesti…</li>
    </ul>
  );
  const formInfo2 = (
    <ul>
      <li>Koen olevani yksinäinen</li>
      <li>Koen turvattomuutta tai olen pelokas</li>
      <li>Muistini on huomattavasti heikentynyt</li>
      <li>Olen ärtynyt, masentunut tai ahdistunut</li>
      <li>Käyttäydyn levottomasti tai aggressiivisesti</li>
      <li>
        En pysty huolehtimaan puhtaudestani (suihkussa käynti, wc:ssä käynti)
      </li>
      <li>En pysty ruokailemaan itsenäisesti</li>
      <li>Tasapainoni on huono ja kaatuilen</li>
      <li>
        Turvallisuuteni on vaarassa (esim. liesi unohtuu päälle tai kynttilät
        jäävät palamaan)
      </li>
      <li>Jätän ottamatta lääkkeitä tai otan niitä liikaa</li>
      <li>En löydä ulkoa takaisin kotiin</li>
      <li>En pysty ulkoilemaan itsenäisesti turvallisuuteni vuoksi</li>
      <li>En pysty hoitamaan laskuja tai muita raha-asioita</li>
      <li>Läheiseni on uupunut tai väsyy koska valvon öisin</li>
      <li>Läheiseni on minusta huolissaan</li>
      <li>Läheiseni pelkää asumista kanssani</li>
    </ul>
  );

  const formInfo3 = (
    <ul>
      <li>
        Turvallisuudestani huolehditaan kaikin tavoin, kuten…(esim.
        henkilökuntaa on riittävästi, minulla on turvaranneke käytössäni)
      </li>
      <li>Saan apua kaikkiin tarvitsemiini asioihin ympäri vuorokauden</li>
      <li>
        Koti on tarpeisiini sopiva (esim. oikeanlainen sänky, wc-korotukset)
      </li>
      <li>Minulla on oma keittiö, jossa voin esim. keittää kahvia</li>
      <li>Saan kaiken ruuan valmiina</li>
      <li>Minulla on oma huone</li>
      <li>
        Huoneeni on viihtyisä ja voin sisustaa sitä minulle rakkailla esineillä,
        kuten… (esim. läheisten valokuvat, huonekalut)
      </li>
      <li>
        Minulla on mahdollisuus itsenäiseen ulkoiluun turvallisuuteni huomioiden
      </li>
      <li>
        Saan tehdä niitä asioita, joista olen aina pitänyt (esim. harrastaa
        liikuntaa, musiikkia)
      </li>
      <li>Hoidossani huomioidaan tarpeeni ja toiveeni yksilöllisesti</li>
      <li>Koti sijaitsee lähellä omaisiani ja läheisiäni</li>
      <li>
        Koti sijaitsee minulle ennestään tutulla alueella (esim. oma kotikunta)
      </li>
      <li>Olen käynyt tutustumassa uuteen kotiini ennen sinne muuttoa</li>
      <li>Saan asua kodissa yhdessä omaiseni tai läheiseni kanssa</li>
    </ul>
  );

  const formInfo4 = (
    <ul>
      <li>Palvelutalo:</li>
      <li>Kunta:</li>
    </ul>
  );
  return (
    <Layout title='Muistisairaan oman tahdon ilmaisu'>
      <Section variant='blue' topMargin='0'>
        <Section variant='blue' maxWidth='md' topMargin='0'>
          <div style={{ paddingBottom: 30 }}>
            <a href='/'>Etusivu</a> {'>'} Muistisairaan oman tahdon ilmaisu
          </div>
          <div className='section--wrapper white--wrapper'>
            <p className='no-print'>
              Tämä lomake on tarkoitettu Sinulle, joka sairastat
              muistisairautta. Voit kirjoittaa tähän lomakkeeseen toiveitasi,
              jotka liittyvät nykyiseen kotiisi ja siihen paikkaan, jossa
              haluaisit asua silloin, jos muistisairautesi haittaa asumistasi
              nykyisessä kodissasi. Halutessasi voit katsoa vinkkejä
              lomakkeeseen kirjattavista asioista painamalla +-merkkiä lomakkeen
              eri kohdissa.
            </p>
            <p className='no-print'>
              Kirjaamistasi toiveista on hyvä keskustella läheistesi kanssa,
              jotta he ovat niistä tietoisia. He voivat myös edistää niiden
              toteutumista. Lomakkeen alaosassa on tila, johon Sinä voit
              halutessasi kirjoittaa niiden henkilöiden nimet, joiden kanssa
              olet keskustellut toiveistasi.
            </p>
            <p className='no-print'>
              Voit täyttää lomakkeen itsenäisesti tai yhdessä läheistesi tai
              esimerkiksi muistihoitajan kanssa. Tulosta lomake sen täyttämisen
              jälkeen ja talleta se paikkaan, josta se on tarvittaessa
              löydettävissä, tai jaa se läheisillesi.
            </p>
            <p className='no-print'>
              <a href='/tulostusohje.pdf' title='Tulostusohje'>
                Tulostusohje (PDF 0,2Mt)
              </a>
            </p>
            <h2>Omat toiveeni kotiani koskien</h2>

            <p>
              <label for='formfield1'>
                Toivon, että nykyisessä kodissani huomioidaan muistisairauteni
                edetessä seuraavat asiat:
              </label>
            </p>
            <FormInfo
              text={formInfo1}
              plusindex={9999999}
              textindex={9999998}
            />
            <TextareaAutosize
              rows={5}
              value={field1}
              id='formfield1'
              className='autoresize-field'
              onChange={(e) => dispatch(setFormField('field1', e.target.value))}
            />

            <p>
              <label for='formfield2'>
                Haluan, että uudesta kodistani ja siihen muuton tarpeesta
                keskustellaan, siinä tilanteessa, jos…
              </label>
            </p>
            <FormInfo
              text={formInfo2}
              plusindex={9999997}
              textindex={9999996}
            />
            <TextareaAutosize
              rows={5}
              value={field2}
              id='formfield2'
              className='autoresize-field'
              onChange={(e) => dispatch(setFormField('field2', e.target.value))}
            />
            <p>
              <label for='formfield3'>
                Toivon, että uudessa kodissani toteutuvat seuraavat asiat...
              </label>
            </p>
            <FormInfo
              text={formInfo3}
              plusindex={9999995}
              textindex={9999994}
            />
            <TextareaAutosize
              rows={5}
              value={field3}
              id='formfield3'
              className='autoresize-field'
              onChange={(e) => dispatch(setFormField('field3', e.target.value))}
            />

            <p>
              <label for='formfield4'>
                Toivon, että voisin tulevaisuudessa asua (esim. tietty
                palvelutalo, kunta)…
              </label>
            </p>
            <FormInfo
              text={formInfo4}
              plusindex={9999993}
              textindex={9999992}
            />
            <TextareaAutosize
              rows={5}
              value={field4}
              id='formfield4'
              className='autoresize-field'
              onChange={(e) => dispatch(setFormField('field4', e.target.value))}
            />
            <p>
              <label for='formfield5'>Nimeni:</label>
            </p>
            <TextField
              fullWidth
              variant='outlined'
              id='formfield5'
              value={field5}
              onChange={(e) => dispatch(setFormField('field5', e.target.value))}
            />

            <p>
              <label for='formfield6'>
                Paikka ja aika, jolloin täytin lomakkeen:
              </label>
            </p>
            <TextField
              fullWidth
              variant='outlined'
              id='formfield6'
              value={field6}
              onChange={(e) => dispatch(setFormField('field6', e.target.value))}
            />

            <p>
              <label for='formfield7'>
                Henkilöt, joiden kanssa olen keskustellut toiveistani:
              </label>
            </p>
            <TextField
              fullWidth
              variant='outlined'
              id='formfield7'
              value={field7}
              onChange={(e) => dispatch(setFormField('field7', e.target.value))}
            />

            <p>
              <label for='formfield8'>Allekirjoitus:</label>
            </p>
            <TextField
              fullWidth
              variant='outlined'
              id='formfield8'
              value={field8}
              onChange={(e) => dispatch(setFormField('field8', e.target.value))}
            />
            <span className='no-print'>
              <br />
              (voit halutessasi lisätä allekirjoituksesi tähän lomakkeen
              tulostuksen jälkeen)
            </span>

            <div style={{ width: '100%', textAlign: 'right' }}>
              <Button
                className='submit'
                variant='outlined'
                onClick={() => window.print()}
              >
                Tulosta
              </Button>
            </div>
          </div>
          <FooterNavi backlinkText='Takaisin alkuun' />
        </Section>
      </Section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, { setFormField })(Form);
