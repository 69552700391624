import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import Question from '../../components/Question/Question';
import QuestionsNavi from '../../components/QuestionsNavi/QuestionsNavi';
import FooterNavi from '../../components/FooterNavi/FooterNavi';
import {
  setCurrentCategory,
  setCurrentQuestion,
  clearCurrentChoice,
} from '../../actions/questionActions';

import './Questions.scss';

const Questions = ({
  questions: { data, currentCategory, currentQuestion },
  match: {
    params: { category },
  },
  preInfo: { userIsProfessional, patientLivesAlone },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (userIsProfessional === undefined && patientLivesAlone === undefined) {
      history.push('/preinfo');
    }
    const categoryData = data.find((dat) => dat.slug === category);
    dispatch(setCurrentCategory(categoryData));
  }, [
    dispatch,
    category,
    data,
    history,
    patientLivesAlone,
    userIsProfessional,
  ]);

  const currentCategoryKeys = Object.keys(currentCategory);

  const currentCategoryIndex = data.findIndex((dat) => dat.slug === category);
  const nextCategoryIndex = data[currentCategoryIndex + 1]
    ? currentCategoryIndex + 1
    : currentCategoryIndex;

  const prevCategoryIndex = data[currentCategoryIndex - 1]
    ? currentCategoryIndex - 1
    : currentCategoryIndex;

  const questionsNavi = () => {
    const filledQuestions = data[currentCategoryIndex].items.reduce(
      (acc, el, i) => (el.currentChoiceType ? [...acc, i] : acc),
      []
    );
    return (
      <QuestionsNavi
        size={currentCategory.items.length}
        current={currentQuestion}
        filledQuestions={filledQuestions}
      />
    );
  };

  const renderCurrentQuestion = () => {
    return (
      <Question
        showHint={
          (currentCategory.title === 'Läheisen terveys ja toimintakyky' ||
            currentCategory.title === 'Oma jaksamiseni') &&
          !userIsProfessional &&
          userIsProfessional !== undefined &&
          patientLivesAlone &&
          true
        }
        category={currentCategory.title}
        data={currentCategory.items[currentQuestion]}
        currentChoiceType={
          data[currentCategoryIndex].items[currentQuestion].currentChoiceType
        }
        currentChoiceAnswer={
          data[currentCategoryIndex].items[currentQuestion].currentChoiceAnswer
        }
        amountOfQuestions={currentCategory.items.length}
        currentQuestionNumber={currentQuestion + 1}
      />
    );
  };

  const nextItem = (isPrev, currentItem, maxItems) => {
    dispatch(clearCurrentChoice());
    let nextChoice = 0;
    let newNextItem = currentItem + 1;
    if (isPrev) {
      newNextItem = currentItem - 1;
      nextChoice = nextItem;
    }
    dispatch(
      setCurrentQuestion(currentItem === maxItems ? nextChoice : newNextItem)
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const allCategoryQuestionsFilled = () => {
    const allFilled = data[currentCategoryIndex].items.filter((dat) =>
      Object.prototype.hasOwnProperty.call(dat, 'currentChoiceType')
    );

    if (allFilled.length === data[currentCategoryIndex].items.length)
      return true;
    return false;
  };

  const clearCurrentMoveNextCategory = (nextUrl, currentUrl) => {
    dispatch(clearCurrentChoice());
    if (nextUrl === currentUrl) {
      history.push('/main');
    } else {
      history.push(nextUrl);
    }
  };

  const clearCurrentMoveNextCategoryConfirm = (isPrev) => {
    if (allCategoryQuestionsFilled(currentCategoryIndex)) {
      if (
        window.confirm(
          'Olet nyt vastannut kaikkiin tämän osa-alueen kysymyksiin. Haluatko siirtyä toiseen osa-alueeseen?'
        )
      ) {
        clearCurrentMoveNextCategory(
          `/questions/${
            data[isPrev ? prevCategoryIndex : nextCategoryIndex].slug
          }`,
          `/questions/${data[currentCategoryIndex].slug}`
        );
      }
    } else if (
      /* eslint-disable no-alert */
      window.confirm(
        'Olet siirtymässä toiseen osa-alueeseen, mutta nykyisen osa-alueen kysymyksistä puuttuu vastauksia. Haluatko jatkaa?'
      )
    ) {
      clearCurrentMoveNextCategory(
        `/questions/${
          data[isPrev ? prevCategoryIndex : nextCategoryIndex].slug
        }`,
        `/questions/${data[currentCategoryIndex].slug}`
      );
    } else {
      return false;
    }
    return false;
  };

  const continueButton = () => {
    const isCategoryLastQuestion =
      currentQuestion === currentCategory.items.length - 1;
    return (
      <Button
        className='submit'
        variant='outlined'
        onClick={() =>
          isCategoryLastQuestion
            ? clearCurrentMoveNextCategoryConfirm(false)
            : nextItem(false, currentQuestion, currentCategory.items.length - 1)
        }
      >
        {isCategoryLastQuestion ? 'Jatka' : 'Jatka'}
      </Button>
    );
  };

  const prevButton = () => {
    const isCategoryFirstQuestion = currentQuestion === 0;

    if (currentCategoryIndex === 0 && currentQuestion === 0) {
      return '';
    }

    return (
      <Button
        className='submit'
        variant='outlined'
        onClick={() =>
          isCategoryFirstQuestion
            ? clearCurrentMoveNextCategoryConfirm(true)
            : nextItem(true, currentQuestion, currentCategory.items.length - 1)
        }

        // onClick={() =>
        //   nextItem(true, currentQuestion, currentCategoryIndex - 1)
        // }
      >
        Edellinen
      </Button>
    );
  };

  if (currentCategoryKeys.length === 0) return '';

  return (
    <Layout>
      <Section variant='blue' topMargin='0'>
        <FooterNavi
          backlink='/main'
          backlinkText='Takaisin päänäkymään'
          allCategoryQuestionsFilled={allCategoryQuestionsFilled(
            currentCategoryIndex
          )}
          top
        />
        <Section marginBottom='sm'>
          <div className='questions--header--navi'>
            <h2 className='questions--title'>{currentCategory.title}</h2>
            <div className='questions--navi'>
              {questionsNavi(currentCategoryIndex)}
            </div>
          </div>
        </Section>

        <div>{renderCurrentQuestion(currentCategoryIndex)}</div>

        <FooterNavi
          backlink='/main'
          backlinkText='Takaisin päänäkymään'
          allCategoryQuestionsFilled={allCategoryQuestionsFilled(
            currentCategoryIndex
          )}
          prevlink={prevButton(currentCategoryIndex, prevCategoryIndex)}
          nextlink={continueButton(currentCategoryIndex, nextCategoryIndex)}
        />
      </Section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  questions: state.questions,
  preInfo: state.preinfo,
});

export default connect(mapStateToProps, {
  setCurrentCategory,
  setCurrentQuestion,
  clearCurrentChoice,
})(Questions);
