import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import './Section.scss';

const ContainerVariant = withStyles(() => ({
  root: (props) => ({
    backgroundColor: props.variant,
    borderRadius: props.borderRadius,
    marginTop: props.topmargin,
  }),
}))(Container);

const Section = ({ children, variant, topMargin, maxWidth, marginBottom }) => {
  const bottomMargin = marginBottom === 'sm' ? ' smallMarginBottom' : '';

  const variantSelection = ` ${variant}--wrapper`;
  return (
    <>
      <div className={`section--wrapper${bottomMargin}${variantSelection}`}>
        <ContainerVariant
          maxWidth={maxWidth}
          variant={variantSelection}
          topmargin={topMargin}
        >
          {children}
        </ContainerVariant>
      </div>
    </>
  );
};

Section.defaultProps = {
  variant: 'white',
  borderRadius: '5px',
  topMargin: 0,
  maxWidth: 'md',
  marginBottom: 'md',
};

export default Section;
