import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearPreInfo } from '../../actions/preinfoActions';
import { clearQuestions } from '../../actions/questionActions';

const Reset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clearAll = () => {
    dispatch(clearQuestions());
    dispatch(clearPreInfo());
    history.push('/');
  };

  return (
    <button onClick={() => clearAll()} id='reset' type='button'>
      Reset
    </button>
  );
};

export default connect(null, { clearQuestions, clearPreInfo })(Reset);
