import React, { useState } from 'react';
import './FormInfo.scss';

const FormInfo = ({ text, plusindex, textindex }) => {
  const [infoVisible, setInfoVisible] = useState(false);
  return (
    <>
      <div className='formplus--holder no-print'>
        <div
          className='plus'
          style={{ zIndex: plusindex }}
          onClick={() => setInfoVisible(!infoVisible)}
          onKeyPress={() => setInfoVisible(!infoVisible)}
          role='button'
          tabIndex={0}
        >
          +
        </div>
      </div>
      <div
        className='forminfo--holder no-print'
        style={{ display: infoVisible ? 'block' : 'none' }}
      >
        <div className='text' style={{ zIndex: textindex }}>
          {text}
        </div>
      </div>
    </>
  );
};

export default FormInfo;
