import React from 'react';

import Section from '../../components/Section/Section';

import Layout from '../../components/Layout/Layout';
import FooterNavi from '../../components/FooterNavi/FooterNavi';
import './Cards.scss';

const Cards = () => {
  return (
    <Layout title='Muistava-kuvakortit'>
      <Section variant='blue' maxWidth='md' topMargin='0'>
        <div style={{ paddingBottom: 30 }}>
          <a href='/'>Etusivu</a> > Muistava-kuvakortit
        </div>
        <div className='section--wrapper white--wrapper'>
          <img
            src='/muistava-kuvakortit.png'
            alt='Muistava kuvakortit'
            class='cardimage'
          />
          <p>
            Tämä työkalu on tarkoitettu muistisairautta sairastavalle, hänen
            omaisilleen, läheisilleen ja ammattilaisille. Kuvakortit sisältävät
            8 teemaa ja niihin liittyviä keskustelunaihe-ehdotuksia. Teemoihin
            liittyviä kuvia ja keskustelunaiheita voidaan hyödyntää
            keskusteltaessa esimerkiksi muistisairaan asumistilanteesta, sen
            mahdollisista tulevista muutoksista ja asumiseen liittyvistä
            toiveista. Kaikkia kortteja ei tarvitse käydä läpi yhdellä kerralla.
            Kuvakorteista on laadittu kaksi eri versioita (nais- ja
            mieskuvitus).
          </p>

          <p>
            Kuvakorttien sisältämät teemat ovat:
            <ul>
              <li>Koti ja asuinympäristö</li>
              <li>Arjen sujuminen</li>
              <li>Sosiaaliset suhteet</li>
              <li>Terveydentila</li>
              <li>Turvallisuus</li>
              <li>Kommunikointi muiden kanssa</li>
              <li>Käyttäytymisen muutokset</li>
              <li>Omaiset ja läheiset</li>
            </ul>
          </p>

          <p>
            Eri aiheista keskusteltaessa voidaan hyödyntää esimerkiksi seuraavia
            kysymysmuotoja:
            <ul>
              <li>Mitä toiveita sinulla on asumisestasi, jos…?</li>
              <li>
                Mitä ajattelet pärjäämisestäsi nykyisessä kodissasi, jos…?
              </li>
              <li>
                Missä tilanteessa toivot asumistasi suunniteltavan uudelleen?
              </li>
            </ul>
          </p>

          <p>
            Kuvakortteja voidaan käyttää digitaalisessa muodossa tietokoneella,
            tablettitietokoneella tai älypuhelimella, tai ne voi tulostaa omaan
            käyttöönsä (kaikki versiot ovat tulostettavissa). Kuvakorttien
            kuvista on myös saatavissa kuvat, joita muistisairautta sairastava
            voi värittää halutessaan keskustelun lomassa.
          </p>

          <h3>Lataa kuvakortit</h3>
          <p>
            <a
              href='/kortit/TIETOKONE_Kortit_Muistava_NAISET.pdf'
              target='_blank'
            >
              Kuvakortit - tietokoneversio - naiskuvitus (PDF 1Mt)
            </a>
            <br />
            <a
              href='/kortit/TIETOKONE_Kortit_Muistava_MIEHET.pdf'
              target='_blank'
            >
              Kuvakortit - tietokoneversio - mieskuvitus (PDF 1Mt)
            </a>
            <br />
            <br />
            <a href='/kortit/TABLET_Kortit_Muistava_NAISET.pdf' target='_blank'>
              Kuvakortit - tablettitietokoneversio - naiskuvitus (PDF 0,9Mt)
            </a>
            <br />
            <a href='/kortit/TABLET_Kortit_Muistava_MIEHET.pdf' target='_blank'>
              Kuvakortit - tablettitietokoneversio - mieskuvitus (PDF 0,9Mt)
            </a>
            <br />
            <br />
            <a
              href='/kortit/MOBIILI_Kortit_Muistava_NAISET.pdf'
              target='_blank'
            >
              Kuvakortit - älypuhelinversio - naiskuvitus (PDF 1,2Mt)
            </a>
            <br />
            <a
              href='/kortit/MOBIILI_Kortit_Muistava_MIEHET.pdf'
              target='_blank'
            >
              Kuvakortit - älypuhelinversio - mieskuvitus (PDF 1,2Mt)
            </a>
            <br />
            <br />
            <a
              href='/kortit/Muistava-kortit_varitettavat_kuvat.pdf'
              target='_blank'
            >
              Kuvakortit - väritettävät kuvat (PDF 1,7Mt)
            </a>
          </p>
        </div>
        <FooterNavi />
      </Section>
    </Layout>
  );
};

export default Cards;
