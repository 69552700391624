import { createStore, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
// import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import storageSession from 'redux-persist/lib/storage/session';
// import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  timeout: 1000,
  // stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const middleware = [thunk];

const store = createStore(
  persistedReducer,
  initialState,
  // composeWithDevTools(applyMiddleware(...middleware)),
  applyMiddleware(...middleware)
);

export default store;
