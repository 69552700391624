import {
  SET_CURRENT_CATEGORY,
  SET_CURRENT_QUESTION,
  SET_CURRENT_CHOICE,
  SET_QUESTIONS_DATA,
  CLEAR_CURRENT_CHOICE,
  CLEAR_QUESTIONS,
} from '../actions/types';

import DATA_CLOSE_CARING from './data/close-caring';
import DATA_CLOSE_NOTCARING from './data/close-notcaring';
import DATA_PROFESSIONAL_CARING from './data/professional-caring';
import DATA_PROFESSIONAL_NOTCARING from './data/professional-notcaring';

const initialState = {
  data: [],
  currentCategory: {},
  currentQuestion: 0,
  currentChoice: null,
  currentChoiceType: null,
  currentChoiceAnswer: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      };
    case SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case SET_CURRENT_CHOICE: {
      const currentCategorySlug = state.currentCategory.slug;
      const currentCategoryKey = state.data.findIndex(
        (dat) => dat.slug === currentCategorySlug
      );

      const newData = [...state.data];
      newData[currentCategoryKey].items[
        state.currentQuestion
      ].currentChoiceType = action.payload.type;
      newData[currentCategoryKey].items[
        state.currentQuestion
      ].currentChoiceAnswer = action.payload.answer;
      return {
        ...state,
        data: newData,
        currentChoiceType: action.payload.type,
        currentChoiceAnswer: action.payload.answer,
      };
    }
    case SET_QUESTIONS_DATA:
      if (action.payload === 'close-caring')
        return { ...state, data: DATA_CLOSE_CARING };
      if (action.payload === 'close-notcaring')
        return { ...state, data: DATA_CLOSE_NOTCARING };
      if (action.payload === 'professional-caring')
        return { ...state, data: DATA_PROFESSIONAL_CARING };
      if (action.payload === 'professional-notcaring')
        return { ...state, data: DATA_PROFESSIONAL_NOTCARING };
      return state;
    case CLEAR_CURRENT_CHOICE:
      return {
        ...initialState,
        data: state.data,
        currentCategory: state.currentCategory,
      };
    case CLEAR_QUESTIONS:
      return initialState;
    default:
      return state;
  }
};
