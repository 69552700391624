import React from 'react';

import './Footer.scss';

import hotusLogo from './logo-hotus.jpg';
import muistiliittoLogo from './logo-muistiliitto.png';
import veikkausLogo from './logo-veikkaus.png';

const Footer = () => {
  return (
    <div className='footer no-print'>
      <a href='https://www.hotus.fi' target='_blank' rel='noopener noreferrer'>
        <img
          src={hotusLogo}
          alt='Hotus'
          className='logo--hotus'
          style={{ paddingTop: 3, paddingRight: 10 }}
        />
      </a>
      <a
        href='https://www.muistiliitto.fi'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          src={muistiliittoLogo}
          alt='Muistiliitto'
          className='logo--muistiliitto'
          style={{ paddingRight: 30 }}
        />
      </a>
      <a
        href='https://www.veikkaus.fi'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={veikkausLogo} alt='Veikkaus' className='logo--veikkaus' />
      </a>
    </div>
  );
};

export default Footer;
