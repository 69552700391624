const APP_DATA = [
  {
    title: 'Asuinympäristö',
    slug: 'asuinymparisto',
    items: [
      {
        category: 'Asuintilat',
        question:
          'Onko asuintiloissa tai -olosuhteissa tapahtunut muutoksia ja miten läheisesi pärjää nykyisessä asuinympäristössä? ',
        positive: [
          'Ei ole tapahtunut muutoksia ja pärjäämme/läheiseni pärjää asuintiloissa hyvin.',
        ],
        neutral: [
          'On tapahtunut muutoksia huonompaan (esim. hissi pois käytöstä tai lähipalveluita lakkautettu), mutta muutokset ovat vain väliaikaisia tai pärjäämme/läheiseni pärjää nykyisissä asuintiloissa kodin muutostöiden tai kotiin tuotavien lisätukimuotojen turvin.',
        ],
        negative: [
          'On tapahtunut muutos huonompaan ja me emme/läheiseni ei pärjää asuintiloissa.',
          'Ei ole tapahtunut muutoksia, mutta me emme/läheiseni ei silti pärjää asuintiloissa.',
        ],
      },
      {
        category: 'Asiointi ja liikkuminen kodin ulkopuolella',
        question:
          'Onko läheisesi mahdollisuuksissa liikkua kodin ulkopuolella tapahtunut muutoksia (esim. kaupassa käynti)?',
        positive: [
          'Hän ajaa luvallisesti ja turvallisesti omaa autoa, käyttää julkisia kulkuvälineitä, kuljetuspalveluita tai läheisten kyytiapua tai pystyy liikkumaan esimerkiksi pyörällä tai kävellen.',
          'Hän on menettänyt ajolupansa, mutta tottunut hyödyntämään muita liikkumismuotoja kodin ulkopuolella.',
        ],
        neutral: [
          'Hän on menettänyt ajolupansa tai liikkuminen ei julkisilla kulkuvälineillä, kävellen tai pyörällä luonnistu. Liikkuminen kodin ulkopuolella saadaan kuitenkin järjestymään uusin tukimuodoin.',
        ],
        negative: [
          'Liikkuminen kodin ulkopuolella ei ole turvallista tai se ei ole mahdollista.',
        ],
      },
    ],
  },
  {
    title: 'Päivittäiset askareet',
    slug: 'paivittaiset-askareet',
    items: [
      {
        category: 'Ruokailu ja ruoan laittaminen',
        question:
          'Onko läheiselläsi vaikeuksia kaupassa käymisessä tai ruoan laitossa?',
        positive: [
          'Ei ole, hän pystyy käymään kaupassa ja laittamaan ruokaa itsenäisesti.',
          'On, mutta hän pystyy käymään kaupassa tai laittamaan ruokaa avustettuna, ne hoidetaan hänen puolestaan tai siihen on muut riittävät tukipalvelut.',
        ],
        negative: [
          'On vaikeuksia. Ruoanlaitto tai kaupassa käyminen ei onnistu avustettuna, niitä ei pystytä hoitamaan hänen puolestaan, eivätkä tukipalvelut ole nykyisellään riittävät.',
        ],
      },
      {
        category: 'Ruokailu ja ruoan laittaminen',
        question:
          'Onko läheiselläsi vaikeuksia ruokailussa (syöminen ja juominen)?',
        positive: ['Ei ole vaikeuksia.'],
        neutral: [
          'On vaikeuksia, mutta hän pystyy ruokailemaan avustettuna tai siihen on riittävät tukipalvelut.',
        ],
        negative: [
          'On vaikeuksia. Hän ei pysty ruokailemaan itsenäisesti, häntä ei pystytä avustamaan siinä riittävästi, eivätkä tukipalvelut ole nykyisellään riittävät.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question:
          'Onko läheiselläsi vaikeuksia peseytymisessä tai pukeutumisessa (mukaan lukien suuhygienia)?',
        positive: [
          'Ei ole vaikeuksia.',
          'On, mutta hän pystyy peseytymään ja pukeutumaan avustettuna tai niihin on riittävät tukipalvelut.',
        ],
        negative: [
          'On vaikeuksia, hän ei selviydy peseytymisestä tai pukeutumisesta avustettuna, eivätkä tukipalvelut ole nykyisellään riittävät.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question: 'Onko läheiselläsi vaikeuksia WC-käynneissä?',
        positive: ['Ei ole vaikeuksia.'],
        neutral: [
          'On, mutta hän pystyy käymään wc:ssä avustettuna tai siihen on riittävät tukipalvelut.',
        ],
        negative: [
          'On vaikeuksia, hän ei pysty käymään wc:ssä itsenäisesti tai avustettuna, eivätkä tukipalvelut ole riittävät.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question:
          'Onko läheiselläsi tuhrimista, virtsankarkailua tai eritteillä sotkemista?',
        positive: [
          'Ei ole, tai jos on, se on vähäistä ja hän pystyy huolehtimaan hygieniastaan itsenäisesti, avustettuna tai tukipalveluiden turvin.',
        ],
        neutral: [
          'On runsaasti, mutta hän pysyy huolehtimaan hygieniastaan avustettuna tai tukipalveluiden turvin.',
        ],
        negative: [
          'On, eikä hän pysty huolehtimaan hygieniastaan avustettuna, eikä tukipalveluiden turvin.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question:
          'Onko läheiselläsi vuodenaikaan tai vuorokaudenaikaan nähden epätyypillistä pukeutumista?',
        positive: ['Ei ole.'],
        neutral: [
          'Kyllä satunnaisesti, mutta riski lähteä ulos vääränlaisessa vaatetuksessa on pieni.',
        ],
        negative: [
          'Kyllä, ja on riski, että hän esimerkiksi lähtee ulos kesävaatteissa talvipakkasella.',
        ],
      },
      {
        category: 'Kodinkoneiden ja -välineiden käyttäminen',
        question:
          'Onko läheiselläsi vaikeuksia tuttujen kodinkoneiden tai -välineiden (esim. pesukone) käytössä?',
        positive: [
          'Ei ole vaikeuksia.',
          'Hän pystyy käyttämään kodinkoneita tai -välineitä avustettuna.',
          'Hänellä on vaikeuksia käyttää tuttuja kodinkoneita tai -välineitä itsenäisesti, mutta kodin askareet pystytään hoitamaan hänen puolestaan tai niihin on riittävät tukipalvelut.',
        ],
        neutral: [
          'Hänellä on vaikeuksia käyttää tuttuja kodinkoneita tai -välineitä itsenäisesti tai avustettuna, eikä kodin askareita pystytä hoitamaan hänen puolestaan, eivätkä tukipalvelut ole nykyisellään riittävät.',
        ],
      },
      {
        category: 'Kodinkoneiden ja -välineiden käyttäminen',
        question:
          'Onko läheiselläsi vaikeuksia apuvälineiden (esim. turvaranneke, kuulokoje) käytössä?',
        positive: [
          'Ei ole vaikeuksia.',
          'On vaikeuksia, mutta hän pystyy käyttämään apuvälineitä avustettuna, eikä avuntarve uhkaa hänen turvallisuuttaan.',
        ],
        neutral: ['On vaikeuksia ja se uhkaa hänen turvallisuuttaan.'],
      },
      {
        category: 'Vuorokausirytmi',
        question: 'Onko läheisesi vuorokausirytmi sekaisin?',
        positive: ['Ei ole.'],
        neutral: [
          'On, hän valvoo tai vaeltelee öisin ja nukkuu päivisin, mutta se ei vaikuta hänen kotona pärjäämiseensä tai hänen kanssaan asuvan arkitoimiin tai jaksamiseen.',
        ],
        negative: [
          'On, hän valvoo tai vaeltelee öisin ja nukkuu päivisin, ja se vaikuttaa hänen kotona pärjäämiseensä tai hänen kanssaan asuvan arkitoimiin tai jaksamiseen.',
        ],
      },
      {
        category: 'Rahan käyttö',
        question:
          'Onko läheiselläsi vaikeuksia pankissa tai pankkiautomaatilla asioimisessa tai laskujen maksamisessa?',
        positive: [
          'Ei ole.',
          'On, mutta häntä pystytään avustamaan pankki- ja laskuasioissa tai siihen on riittävät tukipalvelut.',
        ],
        neutral: [
          'On, eikä hänen pankki- ja laskuasioitaan pystytä hoitamaan hänen puolestaan, eivätkä tukipalvelut ole riittävät.',
        ],
      },
      {
        category: 'Rahan käyttö',
        question: 'Onko läheiselläsi vaikeuksia rahan tunnistamisessa?',
        positive: ['Ei ole.'],
        neutral: [
          'On, sekä vaikeuksia ymmärtää rahan käyttöön liittyviä asioita.',
        ],
      },
      {
        category: 'Rahan käyttö',
        question:
          'Onko läheisesi taloudellisen hyväksikäytön riski kasvanut tai onko hyväksikäyttöä jo tapahtunut?',
        positive: [
          'Ei ole. Hän pystyy pitämään huolta omasta omaisuudestaan tai se on suojattu mahdollisten hyväksikäyttöjen varalta (huom. myös epäasialliset puhelinmyyjät ym. kaupustelijat).',
        ],
        neutral: [
          'On. Hänen taloudellisen hyväksikäyttönsä riski on kasvanut tai hyväksikäyttöä on jo tapahtunut.',
        ],
      },
      {
        category: 'Kotiin tuotavien palvelujen käyttö',
        question:
          'Onko kotiinne/läheisesi kotiin tuotavien palveluiden käyttö lisääntynyt tai runsasta?',
        positive: [
          'Palveluiden käyttö on pysynyt ennallaan tai se on määrän lisääntymisestä huolimatta kokonaistilanteeseen nähden sopivaa.',
        ],
        negative: [
          'Palveluiden käyttö tai niiden tarve on lisääntynyt. Runsaasta määrästä huolimatta saatavilla olevat palvelut eivät riitä kotona pärjäämiseen.',
        ],
      },
    ],
  },
  {
    title: 'Sosiaaliset suhteet',
    slug: 'sosiaaliset-suhteet',
    items: [
      {
        category: 'Yhteydenotot omaisiin',
        question:
          'Onko läheisesi sosiaalisissa suhteissa (esim. yhteydenpito muihin läheisiin ja ystäviin) tapahtunut muutosta (lisääntyneet tai vähentyneet)? ',
        positive: [
          'Ei ole tapahtunut muutosta tai niiden sävy ei ole huolta herättävä.',
        ],
        neutral: [
          'On, hän on aiempaan verrattuna vetäytyneempi ja eristäytyneempi sosiaalisista suhteista.',
        ],
        negative: [
          'On, hänen yhteydenottonsa läheisiin ovat lisääntyneet ja niiden sävy aiheuttaa huolta (viittaa esim. yksinäisyyteen, ahdistuneisuuteen tai pelkotiloihin).',
        ],
      },
      {
        category: 'Yhteydenotot omaisiin',
        question: 'Kokeeko läheisesi itsensä yksinäiseksi?',
        positive: ['Ei koe.'],
        neutral: ['Hän on ilmaissut tuntevansa itsensä yksinäiseksi.'],
      },
      {
        category: 'Kiinnostus ulkopuoliseen maailmaan',
        question:
          'Onko läheisesi kiinnostuksessa kodin ulkopuoliseen maailmaan tapahtunut muutosta (esim. lehtien lukeminen, uutisten seuraaminen)?',
        positive: ['Ei ole.'],
        neutral: [
          'On, hän ei seuraa kodin ulkopuolisen maailman tapahtumia entiseen tapaan.',
        ],
      },
    ],
  },
  {
    title: 'Turvallisuus',
    slug: 'turvallisuus',
    items: [
      {
        category: 'Oma ja muiden turvallisuus',
        question:
          'Vaarantaako läheisesi toiminnallaan paloturvallisuuden (esim. lieden päälle jääminen, kynttilöiden varomaton käyttö)?',
        positive: ['Ei vaaranna.'],
        neutral: [
          'Kyllä, hänen toimintansa saattaa vaarantaa paloturvallisuuden, mutta riski on hallinnassa esimerkiksi teknologisilla tukiratkaisuilla.',
        ],
        negative: [
          'Kyllä, hänen toimintansa uhkaa paloturvallisuutta, eikä tilannetta voida korjata teknologisilla tukiratkaisuilla.',
        ],
      },
      {
        category: 'Oma ja muiden turvallisuus',
        question: 'Hamstraako läheisesi tavaroita?',
        positive: ['Ei, tai asuintilojen tavaramäärä on kohtuullinen.'],
        neutral: [
          'Kyllä, ja asumistilojen tavaramäärä on kasvanut niin suureksi, että se uhkaa tukkia asunnon ulostuloväylät.',
        ],
      },
      {
        category: 'Oma ja muiden turvallisuus',
        question: 'Onko poistumisturvallisuus uhattuna?',
        positive: ['Ei ole, hätäpoistuminen onnistuu.'],
        negative: [
          'Kyllä, hän ei osaa poistua asunnosta edes hätätilanteessa (ei esim. osaa avata ovea) tai poistuminen on estetty hänen turvallisuuteensa vedoten.',
        ],
      },
      {
        category: 'Oma ja muiden turvallisuus',
        question: 'Jääkö ulko-ovi tarkoituksettomasti auki?',
        positive: ['Ei jää, tai ulko-oven auki jääminen on hyvin satunnaista.'],
        neutral: [
          'Kyllä, ulko-ovi jää toistuvasti auki kotiin tullessa tai sieltä poistuttaessa.',
        ],
      },
      {
        category: 'Oma ja muiden turvallisuus',
        question:
          'Pyrkiikö läheisesi lähtemään kotoa epäjohdonmukaisesti tai eksyykö hän ulos lähtiessään (esim. ei tunnista kotiaan)?',
        positive: [
          'Ei pyri, ja ulkoilu onnistuu itsenäisesti tai yhdessä toisen kanssa.',
        ],
        negative: [
          'Hän pyrkii lähtemään kotoa epäjohdonmukaisesti tai eksyy ulos lähtiessään.',
        ],
      },
      {
        category: 'Tapaturmaherkkyyden lisääntyminen',
        question: 'Onko läheisesi liikuntakyky heikentynyt aiemmasta?',
        positive: [
          'Ei ole, tai vaikka on, hän pystyy liikkumaan hyvin itsenäisesti, avustettuna tai apuvälineiden kanssa.',
        ],
        negative: [
          'Kyllä, hänen liikuntakykynsä on heikentynyt tai hän ei muutoinkaan pysty liikkumaan itsenäisesti, avustettuna eikä nykyisten apuvälineiden kanssa.',
        ],
      },
      {
        category: 'Tapaturmaherkkyyden lisääntyminen',
        question: 'Onko läheisesi tasapaino heikentynyt ja kaatuileeko hän? ',
        positive: ['Ei ole, hänen tasapainonsa on hyvä.'],
        neutral: [
          'Kyllä, heikentyneen tasapainon vuoksi hänen kaatumisriskinsä on kohonnut tai kaatumisia jo sattunut.',
        ],
      },
      {
        category: 'Tapaturmaherkkyyden lisääntyminen',
        question:
          'Onko läheiselläsi ilmennyt muita tapaturmia tai läheltä piti -tilanteita, jotka johtuvat hänen alentuneesta toimintakyvystään ja/tai asuinmuodon sopimattomuudesta hänen tilanteeseensa?',
        positive: [
          'Ei, merkittäviä tapaturmia tai läheltä piti -tilanteita ei ole sattunut.',
        ],
        neutral: [
          'Kyllä, tapaturmia tai merkittäviä läheltä piti -tilanteita on jo sattunut.',
        ],
      },
      {
        category: 'Turvaranneke',
        question: 'Onko läheiselläsi turvarannekehälytyksiä?',
        positive: [
          'Hänellä ei ole turvaranneketta, eikä hänellä ole siihen tarvetta.',
          'Hänellä on turvaranneke, mutta hälytysten määrä ei ole lisääntynyt ja ne ovat vähäisiä.',
        ],
        neutral: [
          'Hänellä on turvaranneke, mutta hän ei osaa käyttää sitä, tai turvarannekehälytyksiä on paljon tai ne ovat lisääntyneet.',
          'Hänellä ei ole turvaranneketta, mutta sille olisi tarvetta.',
        ],
      },
      {
        category:
          'Ajokäyttäytymiseen ja ulkona liikkumiseen liittyvä tapaturmariski',
        question:
          'Onko läheisesi liikennetapaturmariski kasvanut tai onko liikennetapaturmia jo sattunut? ',
        positive: [
          'Hän noudattaa liikennesääntöjä ulkona liikkuessa eikä liikennetapaturmariski ei ole kasvanut.',
        ],
        neutral: [
          'Liikennetapaturmariski on kohonnut.',
          'Liikennetapaturmia on jo sattunut.',
        ],
      },
    ],
  },
  {
    title: 'Terveys',
    slug: 'terveydentila',
    items: [
      {
        category: 'Terveydentila',
        question:
          'Onko läheisesi terveydentila heikentynyt (mukaan lukien kipu)?',
        positive: [
          'Hänen vointinsa on ennallaan tai muutokset eivät vaikuta arjessa selviytymiseen.',
        ],
        neutral: [
          'Hänen voinnissaan on tapahtunut muutos huonompaan, ja se vaikuttaa hänen arjessa selviytymiseensä.',
        ],
      },
      {
        category: 'Ravitsemustila',
        question:
          'Onko läheiselläsi syömättömyyttä, ruokahaluttomuutta tai laihtumista?',
        positive: ['Ei ole.'],
        neutral: [
          'On, hänen ruokahalunsa on heikentynyt tai hän on laihtunut.',
        ],
      },
      {
        category: 'Muutokset mielialassa',
        question: 'Onko läheiselläsi mielialan laskua?',
        positive: [
          'Ei ole, hänen mielialansa on hyvä, tai mielialan laskua on ollut vain ajoittain.',
        ],
        negative: [
          'Kyllä, hänen mielialassaan on havaittavissa muutosta huonompaan tai hän on ollut alakuloinen jo pidempään.',
        ],
      },
      {
        category: 'Muutokset mielialassa',
        question: 'Onko läheiselläsi ahdistuneisuutta?',
        positive: ['Ei, tai jos on, se on vain tilapäistä.'],
        negative: [
          'Kyllä, hänellä ilmenee lisääntyvää ahdistuneisuutta tai sitä on ilmennyt jo pidempään.',
        ],
      },
      {
        category: 'Muutokset mielialassa',
        question: 'Onko läheiselläsi ärtyneisyyttä?',
        positive: ['Ei, tai jos on, se on tilapäistä.'],
        neutral: [
          'Kyllä, hänellä on ollut lisääntyvää ärtyneisyyttä tai sitä on ilmennyt jo pidempään.',
        ],
      },
      {
        category: 'Muutokset mielialassa',
        question: 'Onko läheisesi persoonallisuus muuttunut?',
        positive: [
          'Ei ole tai jos on, sillä ei ole vaikutusta hänen arjessa pärjäämiseensä.',
        ],
        neutral: [
          'Kyllä ja se vaikuttaa jo jonkin verran hänen arjessa pärjäämiseensä.',
        ],
        negative: [
          'Kyllä ja se vaikuttaa merkittävissä määrin hänen arjessa pärjäämiseensä.',
        ],
      },
      {
        category: 'Lääkkeiden otto',
        question:
          'Jättääkö läheisesi ottamatta lääkkeitä tai kieltäytyykö hän niistä?',
        positive: ['Ei, hän ottaa lääkkeensä pääsääntöisesti ohjeiden mukaan.'],
        neutral: ['Kyllä, toistuvasti.'],
      },
      {
        category: 'Lääkkeiden otto',
        question: 'Piilotteleeko hän lääkkeitä?',
        positive: ['Ei piilottele.'],
        neutral: ['Kyllä piilottelee.'],
      },
      {
        category: 'Lääkkeiden otto',
        question:
          'Käyttääkö hän lääkkeitä ohjeiden vastaisesti (esim. ottaa liikaa lääkkeitä)?',
        positive: [
          'Ei, hän käyttää lääkkeitään ohjeiden mukaisesti.',
          'Kyllä, mutta kyse on ollut yksittäisestä vahingosta.',
        ],
        negative: ['Kyllä, toistuvasti.'],
      },
      {
        category: 'Alkoholin käyttö',
        question: 'Onko läheisesi alkoholinkäyttö lisääntynyt aiemmasta?',
        positive: ['Ei ja se on korkeintaan kohtuullista.'],
        neutral: ['Kyllä, se on runsasta tai muuten herättää huolta.'],
      },
      {
        category: 'Terveydenhuollon palveluiden käyttö',
        question:
          'Ovatko läheisesi hoitojaksot sairaalassa, terveyskeskuksessa tai muissa hoitolaitoksissa lisääntyneet?',
        positive: [
          'Ei, ne ovat vähäisiä tai niissä ei ole tapahtunut merkittävää muutosta.',
        ],
        negative: [
          'Kyllä, määrä on lisääntynyt selvästi aiemmasta tai niiden määrä on jo ennalta runsasta.',
        ],
      },
      {
        category: 'Terveydenhuollon palveluiden käyttö',
        question:
          'Ovatko läheisesi päivystys- tai ensiapukäynnit lisääntyneet?',
        positive: ['Ei, tai määrä on vähäinen.'],
        negative: [
          'Kyllä, hänen päivystys- tai ensiapukäyntiensä määrä on lisääntynyt selvästi aiemmasta tai niiden määrä on jo ennalta runsasta.',
        ],
      },
      {
        category: 'Terveydenhuollon palveluiden käyttö',
        question:
          'Ovatko muistisairasta läheistäsi koskevat yhteydenotot (läheisesi itse tai muiden tekemät yh-teydenotot) sosiaali- ja terveydenhuoltoon lisääntyneet (hätäkeskus, päivystävät puhelimet, ter-veysasemien/-keskusten puhelinneuvonta tai ajanvaraus)?',
        positive: ['Ei, tai ne ovat vähäisiä.'],
        neutral: [
          'Kyllä, ne ovat lisääntyneet selvästi aiemmasta tai niiden määrä on jo ennalta runsasta.',
        ],
      },
    ],
  },
  {
    title: 'Muisti ja kommunikointi',
    slug: 'muisti',
    items: [
      {
        category: 'Kommunikointi',
        question:
          'Onko läheisesi puheen tuottaminen tai ymmärtäminen vaikeutunut aiemmasta?',
        positive: [
          'Ei ole tai jo aiemmin esiintyneet kommunikaatiovaikeudet eivät aiheuta huolta hänen arjessa pärjäämisessä.',
        ],
        neutral: [
          'Kyllä on tai aiemmin jo esiintyneet kommunikaatiovaikeudet vaikeuttavat kotona pärjäämistä.',
        ],
        negative: [
          'Kommunikaatiovaikeudet tekevät kotona asumisesta mahdotonta.',
        ],
      },
      {
        category: 'Kommunikointi',
        question: 'Onko läheisesi kuulo alentunut merkittävästi aiemmasta?',
        positive: [
          'Ei ole tai aiemmin esiintynyt kuulonalenema ei aiheuta huolta hänen asumistilanteessaan.',
        ],
        neutral: [
          'Kyllä on tai jo aiemmin ilmennyt kuulonalenema vaikeuttaa hänen kotona pärjäämistään.',
        ],
      },
      {
        category: 'Kognitiiviset muutokset',
        question: 'Onko läheisesi muisti heikentynyt merkittävästi aiemmasta?',
        positive: [
          'Ei ole, tai muistin alenema ei aiheuta huolta hänen asumistilanteessaan.',
        ],
        neutral: ['Kyllä ja se aiheuttaa huolta hänen kotona pärjäämisestään.'],
      },
      {
        category: 'Kognitiiviset muutokset',
        question:
          'Onko läheiselläsi aiempaa enemmän ajattelun vaikeutta, pysähtyneisyyttä tai hämmentyneisyyttä?',
        positive: ['Ei ole.'],
        neutral: ['Kyllä on jonkin verran.'],
        negative: [
          'Hänellä on merkittävää pysähtyneisyyttä tai aloitekyvyttömyyttä.',
        ],
      },
      {
        category: 'Kognitiiviset muutokset',
        question:
          'Onko läheiselläsi hahmottamisongelmia (esimerkiksi lattian tai verhojen väärinhahmottamista ja niistä aiheutuvaa pelkoa)? ',
        positive: ['Ei ole.'],
        neutral: [
          'Kyllä, mutta ne ovat lieviä eivätkä vaikeuta hänen kotona pärjäämistään.',
        ],
        negative: [
          'Kyllä, ja ne aiheuttavat hänelle pelkotiloja tai vaaratilanteita kotona liikkuessa.',
        ],
      },
    ],
  },
  {
    title: 'Käyttäytymisen muutokset',
    slug: 'kayttaytymisen-muutokset',
    items: [
      {
        category: 'Epäluuloisuus ja harhaisuus',
        question:
          'Piilotteleeko läheisesi aiempaa enemmän tavaroita (esim. rahaa)?',
        positive: ['Ei piilottele tai se on satunnaista.'],
        neutral: [
          'Kyllä piilottelee tai sitä on ilmennyt runsaasti jo aiemmin.',
        ],
      },
      {
        category: 'Epäluuloisuus ja harhaisuus',
        question:
          'Esittääkö läheisesi aiempaa enemmän perättömiä varkaussyytöksiä?',
        positive: ['Ei esitä tai ne ovat satunnaisia.'],
        neutral: [
          'Kyllä esittää tai hän on esittänyt niitä jo aiemmin runsaasti.',
        ],
      },
      {
        category: 'Epäluuloisuus ja harhaisuus',
        question:
          'Onko läheiselläsi harhoja (esim. kuulee tai näkee olemattomia)?',
        positive: ['Ei ole.'],
        neutral: ['Kyllä, mutta se on satunnaista, ohimenevää tai lievää.'],
        negative: [
          'Kyllä ja niitä esiintyy toistuvasti tai merkittävissä määrin.',
        ],
      },
      {
        category: 'Aggressiivisuus ja väkivaltaisuus',
        question:
          'Onko läheisesi käyttäytynyt fyysisesti aggressiivisesti (esim. lyönyt, sylkenyt, potkinut)?',
        positive: ['Ei ole.'],
        negative: [
          'Kyllä, fyysinen aggressiivisuus on lisääntynyt tai sitä on esiintynyt jo aiemmin.  ',
        ],
      },
      {
        category: 'Aggressiivisuus ja väkivaltaisuus',
        question:
          'Onko läheisesi sanallisesti aggressiivinen (esim. kiihtyneisyys, sanallinen uhkailu)?',
        positive: ['Ei ole tai se on satunnaista.'],
        negative: [
          'Kyllä, sanallinen aggressiivisuus on lisääntynyt tai sitä on esiintynyt jo aiemmin',
        ],
      },
      {
        category: 'Aggressiivisuus ja väkivaltaisuus',
        question: 'Vahingoittaako läheisesi itseään?',
        positive: ['Ei.'],
        negative: ['Kyllä.'],
      },
      {
        category: 'Turvattomuuden tunne',
        question: 'Tuoko läheisesi itse esille turvattomuuden tunnetta?',
        positive: ['Ei tuo.'],
        negative: ['Kyllä, hän kertoo turvattomuuden tunteesta.'],
      },
      {
        category: 'Turvattomuuden tunne',
        question:
          'Onko läheisesi tukeutuminen toisiin ihmisiin epätyypillistä tai onko se lisääntynyt?',
        positive: ['Ei ole.'],
        neutral: ['Kyllä, se on epätyypillistä tai lisääntynyt aiemmasta.'],
      },
      {
        category: 'Turvattomuuden tunne',
        question: 'Esiintyykö läheiselläsi pelkotiloja?',
        positive: ['Ei esiinny.'],
        negative: ['Kyllä esiintyy.'],
      },
      {
        category: 'Muut käytösoireet',
        question:
          'Onko läheisesi käyttäytyminen muuttunut tavalla, jonka ympäristö kokee haasteelliseksi? (esim. huutelu, estottomuus)?',
        positive: ['Ei ole.'],
        neutral: ['Kyllä, mutta käyttäytymisen muutokset ovat lieviä.  '],
        negative: ['Kyllä ja muutokset ovat merkittäviä.'],
      },
      {
        category: 'Muut käytösoireet',
        question:
          'Vaihtelevatko läheisesi tunnetilat hänelle epätyypillisesti tai aiemmasta poikkeavasti?',
        positive: ['Eivät vaihtele.'],
        neutral: ['Kyllä vaihtelevat.'],
      },
      {
        category: 'Muut käytösoireet',
        question:
          'Liikkuuko, liikehtiikö tai vaelteleeko läheisesi levottomasti?',
        positive: ['Ei.'],
        neutral: ['Kyllä.'],
      },
    ],
  },
  {
    title: 'Läheisen terveys ja toimintakyky',
    slug: 'laheisen-toimintakyky',
    items: [
      {
        category: 'Terveydentila',
        question: 'Onko oma terveydentilasi heikentynyt aiemmasta?',
        positive: [
          'Ei ole heikentynyt ja kykenen huolehtimaan läheisestäni entiseen tapaan.',
        ],
        neutral: [
          'On heikentynyt ja en kykene huolehtimaan läheisestäni enää ilman tukimuotojen lisäämistä.',
        ],
        negative: [
          'On heikentynyt enkä pysty enää lainkaan huolehtimaan läheisestäni.',
        ],
      },
      {
        category: 'Mieliala',
        question: 'Ilmeneekö sinulla alakuloisuutta tai masentuneisuutta?',
        positive: ['Mielialani on pääsääntöisesti hyvä.'],
        neutral: [
          'Minulla ilmenee lievää alakuloisuutta tai masentuneisuutta.',
        ],
        negative: [
          'Minulla on merkittävää alakuloisuutta tai masentuneisuutta tai läheisestäni huolehtiminen vaarantaa henkisen hyvinvointini.',
        ],
      },
      {
        category: 'Mieliala',
        question: 'Ilmeneekö sinulla ärtyneisyyttä?',
        positive: ['Mielialani on pääsääntöisesti hyvä.'],
        neutral: ['Minulla ilmenee ärtyneisyyttä enemmän kuin aiemmin.'],
        negative: [
          'Minulla on jo merkittävää ärtyneisyyttä tai läheisestäni huolehtiminen vaarantaa henkisen hyvinvointini.',
        ],
      },
      {
        category: 'Toimintakyky',
        question: 'Onko toimintakyvyssäsi tapahtunut muutosta huonompaan?',
        positive: [
          'Toimintakykyni on ennallaan ja kykenen huolehtimaan läheisestäni entiseen tapaan.',
        ],
        neutral: [
          'Toimintakykyni on jonkin verran heikentynyt enkä pysty huolehtimaan läheisestäni ilman tukimuotojen lisäämistä.',
        ],
        negative: [
          'Toimintakykyni on heikentynyt niin, että en ole kykenevä huolehtimaan läheisestäni.  ',
        ],
      },
    ],
  },
  {
    title: 'Oma jaksamiseni',
    slug: 'oma-jaksamiseni',
    items: [
      {
        category: 'Vuorokausirytmi',
        question: 'Ovatko yöunesi muuttuneet rikkonaisiksi?',
        positive: ['Eivät ole, pystyn pääsääntöisesti nukkumaan yöni hyvin.'],
        negative: [
          'Ovat, en pysty nukkumaan öisin esimerkiksi muistisairaan yöllisen levottomuuden vuoksi.',
        ],
      },
      {
        category: 'Elämänlaatu',
        question: 'Oletko tyytyväinen elämäntilanteeseesi?',
        positive: ['Olen tyytyväinen elämäntilanteeseeni.'],
        neutral: [
          'Minulla on lisääntynyttä tyytymättömyyttä omaan elämäntilanteeseeni läheiseni tilanteeseen liittyen.  ',
        ],
        negative: [
          'Minulla on lisääntynyttä tunnetta ahdingosta tai läheiseni huolehtimiseen liittyvän tilanteen sitovuudesta.',
        ],
      },
      {
        category: 'Koettu kuormitus',
        question:
          'Koetko lisääntynyttä henkistä tai tunneperäistä kuormitusta tai uupumista?',
        positive: ['En koe.'],
        neutral: ['Kyllä, olen ajoittain huolissani jaksamisestani.'],
        negative: [
          'Kyllä, minulla olen toistuvasti huolissani jaksamisestani.',
        ],
      },
      {
        category: 'Koettu kuormitus',
        question:
          'Koetko lisääntynyttä fyysistä kuormitusta (esim. peseytymisessä avustamisen yhteydessä)?',
        positive: ['En koe.'],
        neutral: ['Kyllä, koen lisääntynyttä fyysistä kuormitusta.'],
      },
      {
        category: 'Koettu kuormitus',
        question: 'Koetko lisääntynyttä taloudellista taakkaa tai ahdinkoa?',
        positive: ['En koe.'],
        neutral: [
          'Minulla on lisääntynyttä taloudellista taakkaa tai ahdinkoa.',
        ],
      },
      {
        category: 'Koettu kuormitus',
        question: 'Onko sinulla huolta tukitoimien riittävyydestä?',
        positive: ['Ei ole.'],
        negative: [
          'Minulla on lisääntynyttä huolta läheiseni tukitoimien riittävyydestä.',
        ],
      },
      {
        category: 'Väkivaltaisuus',
        question: 'Oletko havainnut olevasi fyysisesti aggressiivinen?',
        positive: ['En.'],
        negative: ['Kyllä.'],
      },
      {
        category: 'Väkivaltaisuus',
        question: 'Oletko havainnut olevasi sanallisesi aggressiivinen?',
        positive: ['En.'],
        negative: ['Kyllä.'],
      },
      {
        category: 'Elämäntilanteen muutokset',
        question:
          'Onko elämäntilanteessasi tapahtunut muutoksia (esim. huollettavien määrän lisääntyminen, muutokset työelämässä), joilla on merkitystä läheisestäsi huolehtimiseen?',
        positive: ['Ei ole.'],
        neutral: [
          'Kyllä on, mutta ne eivät vaikuta läheisestäni huolehtimiseen.',
        ],
        negative: ['Kyllä on ja ne vaikuttavat läheisestäni huolehtimiseen.'],
      },
      {
        category: 'Tukiverkostot',
        question:
          'Onko tukiverkoissasi tapahtunut muutoksia (esim. tukenasi toimineen läheisen muuttaminen tai menehtyminen)?',
        positive: ['Ei ole.'],
        neutral: [
          'Kyllä on, mutta ne eivät vaikuta läheisestäni huolehtimiseen.',
        ],
        negative: ['Kyllä on ja ne vaikuttavat läheisestäni huolehtimiseen.'],
      },
    ],
  },
];

export default APP_DATA;
