import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import './FooterNavi.scss';

const FooterNavi = ({
  top,
  prevlink,
  nextlink,
  backlinkText,
  allCategoryQuestionsFilled,
}) => {
  const history = useHistory();
  const location = useLocation();

  const preinfoClass = location.pathname === '/preinfo' ? ' preinfo' : '';

  const onClickBackToMain = (locationPathName) => {
    if (
      locationPathName.includes('/questions') &&
      !allCategoryQuestionsFilled
    ) {
      if (
        /* eslint-disable no-alert */
        window.confirm(
          'Osa-alueen kysymyksistä puuttuu vastauksia. Haluatko jatkaa?'
        )
      )
        history.push('/main');
    } else if (
      locationPathName.includes('/questions') ||
      locationPathName === '/report'
    ) {
      history.push('/main');
    } else if (locationPathName.includes('/ohje')) {
      history.push('/serviceinfo');
    } else if (locationPathName.includes('/vinkkeja')) {
      history.push('/tips');
    } else if (locationPathName === '/main') {
      history.push('/');
    } else {
      history.goBack();
    }
  };

  return (
    <div className={`footer--navi ${preinfoClass}`}>
      <div
        className={`footer--navi--container${top ? ` footer--navi--top` : ``}`}
      >
        <div
          className='back--to--main--link'
          onClick={() => onClickBackToMain(location.pathname)}
          onKeyPress={() => onClickBackToMain(location.pathname)}
          role='button'
          tabIndex={0}
        >
          <div className='back--button'>&#9668;</div> {backlinkText}
        </div>
      </div>

      <div className='prevnext--navi'>
        {location.pathname !== '/preinfo' && prevlink}
        {nextlink}
      </div>
    </div>
  );
};

FooterNavi.defaultProps = {
  backlink: '/',
  backlinkText: 'Takaisin',
  nextlink: null,
  top: false,
};

export default FooterNavi;
