import {
  SET_USER_PROFESSIONAL,
  SET_PATIENT_LIVES_ALONE,
  CLEAR_PREINFO,
} from './types';

export const setUserProfessional = (professional) => {
  return {
    type: SET_USER_PROFESSIONAL,
    payload: professional,
  };
};

export const setPatientLivesAlone = (livesalone) => {
  return {
    type: SET_PATIENT_LIVES_ALONE,
    payload: livesalone,
  };
};

export const clearPreInfo = () => {
  return {
    type: CLEAR_PREINFO,
  };
};
