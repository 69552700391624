import {
  SET_CURRENT_CATEGORY,
  SET_CURRENT_QUESTION,
  SET_CURRENT_CHOICE,
  CLEAR_CURRENT_CHOICE,
  SET_QUESTIONS_DATA,
  CLEAR_QUESTIONS,
} from './types';

export const setCurrentChoice = (type, answer) => {
  return {
    type: SET_CURRENT_CHOICE,
    payload: { type, answer },
  };
};

export const setCurrentCategory = (category) => {
  return {
    type: SET_CURRENT_CATEGORY,
    payload: category,
  };
};

export const setCurrentQuestion = (item) => {
  return {
    type: SET_CURRENT_QUESTION,
    payload: item,
  };
};

export const setQuestionsData = (source) => {
  return {
    type: SET_QUESTIONS_DATA,
    payload: source,
  };
};

export const clearCurrentChoice = () => {
  return {
    type: CLEAR_CURRENT_CHOICE,
  };
};

export const clearQuestions = () => {
  return {
    type: CLEAR_QUESTIONS,
  };
};

/*export const setCurrentQuestion = (item, callback) => async dispatch => {
  dispatch({
    type: SET_CURRENT_QUESTION,
    payload: item
  });
  callback();
};*/
