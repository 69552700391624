export const SET_USER_PROFESSIONAL = 'SET_USER_PROFESSIONAL';
export const SET_PATIENT_LIVES_ALONE = 'SET_PATIENT_LIVES_ALONE';
export const CLEAR_PREINFO = 'CLEAR_PREINFO';
export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SET_CURRENT_CHOICE = 'SET_CURRENT_CHOICE';
export const CLEAR_CURRENT_CHOICE = 'CLEAR_CURRENT_CHOICE';
export const SET_QUESTIONS_DATA = 'SET_QUESTIONS_DATA';
export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS';
export const SET_FORM_FIELD = 'SET_FORM_FIELD';
export const CLEAR_FORM_FIELDS = 'CLEAR_FORM_FIELDS';
