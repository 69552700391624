export const questionBackgroundColor = (type) => {
  switch (type) {
    case 'positive':
      return 'lightblue';
    case 'neutral':
      return 'lightyellow';
    case 'negative':
      return 'lightred';
    default:
      return 'white';
  }
};

export const radiobuttonColor = (type) => {
  switch (type) {
    case 'positive':
      return '#01AAC5';
    case 'neutral':
      return '#FDB813';
    case 'negative':
      return '#F79389';
    default:
      return '#01AAC5';
  }
};
