import React, { Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setCurrentQuestion } from '../../actions/questionActions';

import './QuestionsNavi.scss';

const QuestionsNavi = ({ size, current, filledQuestions }) => {
  const dispatch = useDispatch();
  let counter = 0;
  return [...Array(size)].map((e, i) => {
    counter += 1;
    const currentClass = current === i ? ' currentItem' : '';
    const filledClass = filledQuestions.includes(i) ? ' filledItem' : '';

    return (
      <Fragment key={`${counter}-frag`}>
        <div
          className={`questionNaviItem${currentClass}${filledClass}`}
          key={`${counter}-div`}
          onClick={() => dispatch(setCurrentQuestion(i))}
          onKeyPress={() => dispatch(setCurrentQuestion(i))}
          role='button'
          tabIndex={0}
        >
          {i + 1}
        </div>
        &nbsp;
      </Fragment>
    );
  });
};

export default connect(null, { setCurrentQuestion })(QuestionsNavi);
