import { SET_FORM_FIELD, CLEAR_FORM_FIELDS } from './types';

export const setFormField = (field, value) => {
  return {
    type: SET_FORM_FIELD,
    payload: { field, value },
  };
};

export const clearFormFields = () => {
  return {
    type: CLEAR_FORM_FIELDS,
  };
};
