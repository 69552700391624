import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';

import Start from './routes/Start/Start';
import PreInfo from './routes/PreInfo/PreInfo';
import Cards from './routes/Cards/Cards';
import Main from './routes/Main/Main';
import ServiceInfo from './routes/ServiceInfo/ServiceInfo';
import Tips from './routes/Tips/Tips';
import Questions from './routes/Questions/Questions';
import Report from './routes/Report/Report';
import Form from './routes/Form/Form';
import Reset from './routes/Reset/Reset';
import Instruction1 from './routes/Instructions/Instruction1';
import Instruction2 from './routes/Instructions/Instruction2';
import Instruction3 from './routes/Instructions/Instruction3';
import Instruction4 from './routes/Instructions/Instruction4';
import AccessibilityInfo from './routes/AccessibilityInfo/AccessibilityInfo';

import NotFound from './routes/NotFound/NotFound';

function App() {
  useEffect(() => {
    persistStore(store);
  }, []);
  const persistor = persistStore(store);
  if (window.location.search === '?reset') {
    persistor.purge();
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Switch>
          <Route exact path='/' component={Start} />
          <Route path='/preinfo' component={PreInfo} />
          <Route path='/cards' component={Cards} />
          <Route path='/main' component={Main} />
          <Route path='/form' component={Form} />
          <Route path='/serviceinfo' component={ServiceInfo} />
          <Route
            path='/ohje-asumistilanteen-arviointityokalun-kayttoon'
            component={Instruction1}
          />
          <Route path='/vinkkeja-toimintakaavio' component={Instruction2} />
          <Route
            path='/vinkkeja-miten-voin-hyodyntaa-muistava-tyokaluja'
            component={Instruction3}
          />
          <Route
            path='/ohje-kuinka-tulostan-ja-tallennan'
            component={Instruction4}
          />
          <Route path='/tips' component={Tips} />
          <Route path='/questions/:category' component={Questions} />
          <Route path='/report' component={Report} />
          <Route path='/reset' component={Reset} />
          <Route path='/saavutettavuusseloste' component={AccessibilityInfo} />
          <Route component={NotFound} />
        </Switch>
      </PersistGate>
    </Provider>
  );
}

export default App;
