import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';

import './Start.scss';

import startImage from './start.png';
import box1 from './icon-kuvakortit.png';
import box3 from './icon-muistisairaan-asumistilanteen-arviointi.png';
import box2 from './icon-muistisairaan-oman-tahdon-ilmaisu.png';

const Content = () => {
  return (
    <>
      <Helmet>
        <title>
          Muistava-työkalu | Hoitotyön tutkimussäätiö & Muistiliitto
        </title>
      </Helmet>

      <div className='init'>
        <div className='init--text'>
          <h1>Tervetuloa käyttämään Muistava-työkaluja!</h1>
          <p>
            Tältä sivustolta löydät työkalut, jotka ovat tarkoitettu tukemaan
            muistisairautta sairastavan henkilön asumistilanteen oikea-aikaista
            puheeksi ottoa ja asumisen suunnittelua.
          </p>

          <p>
            <Link to='/form' id='link-form'>
              Lomake muistisairaan oman tahdon ilmaisuun
            </Link>{' '}
            on tarkoitettu muistisairautta sairastaville. Tällä lomakkeella sinä
            muistisairautta sairastava voit kirjata ylös omia toiveitasi tulevaa
            asumistasi koskien.
          </p>

          <p>
            <Link to='/cards'>Muistava-kuvakortit</Link> on tarkoitettu
            muistisairautta sairastavalle, hänen omaisilleen, läheisilleen ja
            ammattilaisille. Niiden avulla voidaan keskustella nykyisen
            asumismuodon toimivuudesta ja arjen sujumisesta sekä tulevan
            asumisen suunnitelmista.
          </p>

          <p>
            <Link to='/preinfo' id='link-preinfo'>
              Työkalu muistisairaan asumistilanteen arviointiin
            </Link>{' '}
            on tarkoitettu muistisairautta sairastavan omaisille, läheisille ja
            ammattilaisille. Työkalun avulla voidaan kartoittaa muistisairaan
            nykyistä asumistilannetta, sen puheeksi oton ja tarkemman arvioinnin
            tarvetta.
          </p>

          <p>
            Lue lisää työkaluista{' '}
            <Link to='/serviceinfo'>”Tietoa palvelusta”</Link> -sivulta.
          </p>
        </div>
        <div className='init--image'>
          <img src={startImage} alt='' />
        </div>
      </div>
    </>
  );
};

const Start = () => {
  return (
    <Layout>
      <Section maxWidth='lg' topMargin='0'>
        <Content />
      </Section>

      <Section variant='blue' maxWidth='lg'>
        <div className='box--container'>
          <div className='box boxOne'>
            <a href='/form'>
              <img src={box2} alt='Lomake' />
              <span>Muistisairaan oman tahdon ilmaisu</span>
            </a>
          </div>
          <div className='box boxTwo'>
            <a href='/cards'>
              <img src={box1} alt='Lomake' />
              <span>Muistava-kuvakortit</span>
            </a>
          </div>
          <div className='box boxThree'>
            <a href='/preinfo'>
              <img src={box3} alt='Työkalu' border='0' />

              <span>Muistisairaan asumistilanteen arviointi</span>
            </a>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Start;
