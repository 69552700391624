import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { connect, useDispatch } from 'react-redux';
import { setCurrentChoice } from '../../actions/questionActions';
import { questionBackgroundColor, radiobuttonColor } from '../functions';

import Section from '../Section/Section';
import './Question.scss';

/* eslint-disable react/jsx-props-no-spreading */

const CustomRadio = withStyles({
  root: (props) => ({
    color: props.colored,
  }),
  checked: {},
})((props) => <Radio color='default' {...props} />);

const Question = ({
  showHint,
  data,
  currentChoiceAnswer,

  amountOfQuestions,
  currentQuestionNumber,
}) => {
  const dispatch = useDispatch();
  const choice = (type, answer) => {
    if (!answer) return '';

    return (
      <Section
        variant={questionBackgroundColor(type)}
        marginBottom='sm'
        key={answer}
      >
        {/* eslint-disable jsx-a11y/label-has-associated-control */}
        {/* eslint-disable jsx-a11y/label-has-for */}
        <div className='question--holder'>
          <CustomRadio
            colored={radiobuttonColor(type)}
            checked={currentChoiceAnswer === answer && true}
            onChange={() => dispatch(setCurrentChoice(type, answer))}
            id={answer}
            value={answer}
            name='answer'
            inputProps={{ 'aria-label': 'A' }}
            readOnly
          />

          <label htmlFor={answer}>{answer}</label>
        </div>
      </Section>
    );
  };

  return (
    <>
      {showHint && (
        <Section marginBottom='sm'>
          <div className='question--category'>
            Vastaa tätä osioita koskeviin kysymyksiin, mikäli huolehdit
            muistisairaan läheisesi tarpeista ja hän on riippuvainen avustasi.
            Voit vastata kysymyksiin myös toisen muistisairaasta huolehtivan
            läheisen näkökulmasta.
          </div>
        </Section>
      )}

      <Section marginBottom='sm'>
        <div className='question--category'>
          Kysymys {currentQuestionNumber}/{amountOfQuestions} - {data.category}
        </div>
        <div className='question'>{data.question}</div>
      </Section>

      {data.positive &&
        data.positive.map((answer) => {
          return choice('positive', answer);
        })}
      {data.neutral &&
        data.neutral.map((answer) => {
          return choice('neutral', answer);
        })}
      {data.negative &&
        data.negative.map((answer) => {
          return choice('negative', answer);
        })}
    </>
  );
};

const mapStateToProps = (state) => ({
  questions: state.questions,
});

Question.defaultProps = {
  showHint: false,
  data: [],
  currentChoiceType: '',
  currentChoiceAnswer: '',
  amountOfQuestions: 0,
  currentQuestionNumber: 0,
};

export default connect(mapStateToProps, { setCurrentChoice })(Question);
