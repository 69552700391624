import React from 'react';

import Section from '../../components/Section/Section';

import Layout from '../../components/Layout/Layout';
import FooterNavi from '../../components/FooterNavi/FooterNavi';
// import './ServiceInfo.scss';

const Tips = () => {
  return (
    <Layout title='Vinkkejä Muistava-työkalujen käyttöönottoon'>
      <Section variant='blue' maxWidth='md' topMargin='0'>
        <div style={{ paddingBottom: 30 }}>
          <a href='/'>Etusivu</a> > Vinkkejä Muistava-työkalujen käyttöönottoon
        </div>
        <div className='section--wrapper white--wrapper'>
          <p>
            Muistava-työkalut on suunniteltu tukemaan muistisairautta
            sairastavan henkilön asumistilanteen kartoittamista, asumisen
            suunnittelua ja asumiseen liittyvien kysymysten puheeksi ottoa eri
            tilanteissa:
          </p>
          <ul>
            <li>
              <a href='/form'>Lomake muistisairaan oman tahdon ilmaisuun</a> on
              tarkoitettu muistisairautta sairastaville. Tällä lomakkeella sinä
              muistisairautta sairastava voit kirjata ylös omia toiveitasi
              tulevaa asumistasi koskien. Voit täyttää lomakkeen itsenäisesti,
              yhdessä läheisesi kanssa tai kysyä täyttämiseen apua esimerkiksi
              paikallisesta muistiyhdistyksestä.
            </li>
            <li>
              <a href='/cards'>Muistava-kuvakortit</a> on tarkoitettu
              muistisairautta sairastavalle, hänen omaisilleen, läheisilleen ja
              ammattilaisille. Niitä voidaan hyödyntää esimerkiksi perheen
              keskinäisissä keskusteluissa, muistiyhdistysten vertaisryhmissä ja
              muistihoitajan vastaanotolla. Kuvakorttien avulla voidaan
              keskustella nykyisen asumismuodon toimivuudesta ja arjen
              sujumisesta sekä tulevan asumisen suunnitelmista.{' '}
            </li>
            <li>
              <a href='/preInfo'>
                Työkalu muistisairaan asumistilanteen arviointiin
              </a>{' '}
              on tarkoitettu muistisairautta sairastavan omaisille, läheisille
              ja ammattilaisille. Työkalun avulla voidaan kartoittaa
              muistisairaan nykyistä asumistilannetta, sen puheeksi oton ja
              tarkemman arvioinnin tarvetta. Mahdollisista työkalun esille
              tuomista huolenaiheista on hyvä keskustella mahdollisuuksien
              mukaan yhdessä muistisairautta sairastavan ja hänen läheistensä
              kanssa. Apua ja tukea asumistilanteesta keskusteluun saa
              paikallisista muistiyhdistyksistä ja sosiaali- ja terveydenhuollon
              ammattilaisilta.
            </li>
          </ul>

          <p>
            Tulosta tästä huoneentaulut vinkiksi Muistava-työkalujen
            käyttöönottoon!
          </p>
          <p>
            <strong>
              Vinkkejä Muistava-työkalujen valintaan muistisairautta
              sairastaville ja läheisille
            </strong>
            <br />
            <a href='/vinkkeja-toimintakaavio'>Toimintakaavio</a>
            <br />
            <a href='/toimintakaavio_muistava.pdf' target='_blank'>
              Toimintakaavio (ladattava PDF 0,4Mt)
            </a>
          </p>

          <p>
            <strong>
              Vinkkejä Muistava-työkalujen valintaan kolmannen sektorin
              toimijoille ja ammattilaisille
            </strong>{' '}
            <br />
            <a href='/vinkkeja-miten-voin-hyodyntaa-muistava-tyokaluja'>
              Polkumalli
            </a>
            <br />
            <a href='/polkumalli_muistava.pdf' target='_blank'>
              Polkumalli (ladattava PDF 0,6Mt)
            </a>
          </p>
        </div>
        <FooterNavi />
      </Section>
    </Layout>
  );
};

export default Tips;
