const APP_DATA = [
  {
    title: 'Asuinympäristö',
    slug: 'asuinymparisto',
    items: [
      {
        category: 'Asuintilat',
        question:
          'Onko asuintiloissa tai -olosuhteissa tapahtunut muutoksia ja miten muistisairas pärjää nykyisissä tiloissa? ',
        positive: [
          'Ei ole tapahtunut muutoksia ja hän pärjää asuintiloissa hyvin.',
        ],
        neutral: [
          'On tapahtunut muutoksia huonompaan (esim. hissi pois käytöstä tai lähipalveluita lakkautettu), mutta muutokset ovat vain väliaikaisia tai hän pärjää nykyisissä asuintiloissa kodin muutostöiden tai kotiin tuotavien lisätukimuotojen turvin.',
        ],
        negative: [
          ' On tapahtunut muutos huonompaan ja hän ei pärjää asuintiloissa.',
          ' Ei ole tapahtunut muutoksia, mutta hän ei silti pärjää asuintiloissa.',
        ],
      },
      {
        category: 'Asiointi ja liikkuminen kodin ulkopuolella',
        question:
          'Onko muistisairaan mahdollisuuksissa liikkua kodin ulkopuolella tapahtunut muutoksia (esim. kaupassa käynti)?',
        positive: [
          'Hän ajaa luvallisesti ja turvallisesti omaa autoa, käyttää julkisia kulkuvälineitä, kuljetuspalveluita tai läheisten kyytiapua tai pystyy liikkumaan esimerkiksi pyörällä tai kävellen.',
          'Hän on menettänyt ajolupansa, mutta tottunut hyödyntämään muita liikkumismuotoja kodin ulkopuolella.',
        ],
        neutral: [
          'Hän on menettänyt ajolupansa tai liikkuminen ei julkisilla kulkuvälineillä, kävellen tai pyörällä luonnistu. Liikkuminen kodin ulkopuolella saadaan kuitenkin järjestymään uusin tukimuodoin.',
        ],
        negative: [
          'Liikkuminen kodin ulkopuolella ei ole turvallista tai se ei ole mahdollista.',
        ],
      },
    ],
  },
  {
    title: 'Päivittäiset askareet',
    slug: 'paivittaiset-askareet',
    items: [
      {
        category: 'Ruokailu ja ruoan laittaminen',
        question:
          'Onko muistisairaalla vaikeuksia kaupassa käymisessä tai ruoan laitossa?',
        positive: [
          'Ei ole vaikeuksia, hän pystyy käymään kaupassa ja laittamaan ruokaa itsenäisesti.',
        ],
        neutral: [
          'On vaikeuksia, mutta hän pystyy käymään kaupassa tai laittamaan ruokaa läheisen avustamana tai hänellä on niihin riittävät tukipalvelut.',
        ],
        negative: [
          'On vaikeuksia, hän ei pysty käymään kaupassa tai laittamaan ruokaa läheisen avustamana, eivätkä tukipalvelut ole nykyisellään riittävät.',
        ],
      },
      {
        category: 'Ruokailu ja ruoan laittaminen',
        question:
          'Onko muistisairaalla vaikeuksia ruokailussa (syöminen ja juominen)?',
        positive: ['Ei ole vaikeuksia.'],
        negative: [
          'On vaikeuksia, hän pystyy ruokailemaan ainoastaan läheisen avustamana tai tukipalveluiden turvin.',
          'On vaikeuksia, hän ei pysty ruokailemaan itsenäisesti eikä läheisen avustamana, eivätkä tukipalvelut ole riittävät.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question:
          'Onko muistisairaalla vaikeuksia peseytymisessä tai pukeutumisessa (mukaan lukien suuhygienia)?',
        positive: ['Ei ole vaikeuksia.'],
        neutral: [
          'On vaikeuksia, mutta hän pystyy peseytymään ja pukeutumaan läheisen avustaman tai hänellä on niihin riittävät tukipalvelut.',
        ],
        negative: [
          'On vaikeuksia, hän ei selviydy peseytymisestä ja/tai pukeutumisesta läheisen avustamana, eivätkä tukipalvelut ole nykyisellään riittävät.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question: 'Onko muistisairaalla vaikeuksia WC-käynneissä?',
        positive: ['Ei ole vaikeuksia.'],
        negative: [
          'On vaikeuksia, hän pystyy käymään WC:ssä ainoastaan läheisen avustamana tai tukipalveluiden turvin.',
          'On vaikeuksia, hän ei pysty käymään wc:ssä itsenäisesti tai läheisen avustamana, eivätkä tukipalvelut ole riittävät.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question:
          'Onko muistisairaalla tuhrimista, virtsankarkailua tai eritteillä sotkemista?',
        positive: [
          'Ei ole, tai jos on, se on vähäistä ja hän pystyy huolehtimaan hygieniastaan itsenäisesti, läheisen avustamana tai tukipalveluiden turvin.',
        ],
        negative: [
          'On runsaasti ja hän pysyy huolehtimaan hygieniastaan ainoastaan läheisen avustamana tai tukipalveluiden turvin.',
          'On, eikä hän pysty huolehtimaan hygieniastaan itsenäisesti tai avustettuna.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question:
          'Onko muistisairaalla vuodenaikaan tai vuorokaudenaikaan nähden epätyypillistä pukeutumista?',
        positive: ['Ei ole.'],
        neutral: [
          'Kyllä satunnaisesti, mutta riski lähteä ulos vääränlaisessa vaatetuksessa on pieni.',
        ],
        negative: [
          'Kyllä, ja on riski, että hän esimerkiksi lähtee ulos kesävaatteissa talvipakkasella.',
        ],
      },
      {
        category: 'Kodinkoneiden ja -välineiden käyttäminen',
        question:
          'Onko muistisairaalla vaikeuksia tuttujen kodinkoneiden tai -välineiden (esim. pesukone) käytössä?',
        positive: [
          'Ei ole vaikeuksia.',
          'Hän pystyy käyttämään kodinkoneita tai -välineitä läheisen avustamana.',
        ],
        neutral: [
          'Hänellä on vaikeuksia käyttää tuttuja kodinkoneita tai -välineitä itsenäisesti, mutta läheinen pystyy hoitamaan kodin askareet hänen puolestaan, tai niihin on riittävät tukipalvelut.',
        ],
        negative: [
          'Hänellä on vaikeuksia käyttää tuttuja kodinkoneita tai -välineitä itsenäisesti tai läheisen avustamana eikä läheinen ei pysty hoitamaan kodin askareita hänen puolestaan, eivätkä tukipalvelut ole nykyisellään riittävät.',
        ],
      },
      {
        category: 'Kodinkoneiden ja -välineiden käyttäminen',
        question:
          'Onko muistisairaalla vaikeuksia apuvälineiden (esim. turvaranneke, kuulokoje) käytössä?',
        positive: ['Ei ole vaikeuksia'],
        neutral: [
          'On vaikeuksia ja hän pystyy käyttämään apuvälineitä vain läheisen avustamana, mutta avuntarve ei uhkaa hänen turvallisuuttaan.',
        ],
        negative: ['On vaikeuksia ja se uhkaa hänen turvallisuuttaan.'],
      },
      {
        category: 'Vuorokausirytmi',
        question: 'Onko muistisairaan vuorokausirytmi sekaisin?',
        positive: ['Ei ole.'],
        neutral: [
          'On, hän valvoo tai vaeltelee öisin ja nukkuu päivisin, mutta se ei vaikuta hänen kotona pärjäämiseensä.',
        ],
        negative: [
          'On, hän valvoo tai vaeltelee öisin ja nukkuu päivisin, ja se vaikuttaa hänen kotona pärjäämiseensä.',
        ],
      },
      {
        category: 'Rahan käyttö',
        question:
          'Onko muistisairaalla vaikeuksia pankissa tai pankkiautomaatilla asioimisessa tai laskujen maksamisessa?',
        positive: [
          'Ei ole.',
          'On, mutta hän pystyy hoitamaan pankki- ja laskuasiat läheisen avustamana tai hänellä on niihin tarvittavat tukipalvelut.',
        ],
        neutral: [
          'On, eikä hän ei pysty hoitamaan pankki- ja laskuasioita läheisen avustamana, eivätkä tukipalvelut ole riittävät.',
        ],
      },
      {
        category: 'Rahan käyttö',
        question: 'Onko muistisairaalla vaikeuksia rahan tunnistamisessa?',
        positive: ['Ei ole.'],
        negative: [
          'On, sekä vaikeuksia ymmärtää rahan käyttöön liittyviä asioita.',
        ],
      },
      {
        category: 'Rahan käyttö',
        question:
          'Onko muistisairaan taloudellisen hyväksikäytön riski kasvanut tai onko hyväksikäyttöä jo tapahtunut?',
        positive: [
          'Ei ole. Hän pystyy pitämään huolta omasta omaisuudestaan tai se on suojattu mahdollisten hyväksikäyttöjen varalta (huom. myös epäasialliset puhelinmyyjät ym. kaupustelijat).',
        ],
        negative: [
          'On. Hänen taloudellisen hyväksikäyttönsä riski on kasvanut tai hyväksikäyttöä on jo tapahtunut.',
        ],
      },
      {
        category: 'Kotiin tuotavien palvelujen käyttö',
        question:
          'Onko muistisairaan kotiin tuotavien palveluiden käyttö lisääntynyt tai runsasta?',
        positive: [
          'Palveluiden käyttö on pysynyt ennallaan tai se on määrän lisääntymisestä huolimatta kokonaistilanteeseen nähden sopivaa.',
        ],
        negative: [
          'Palveluiden käyttö tai niiden tarve on lisääntynyt. Runsaasta määrästä huolimatta saatavilla olevat palvelut eivät riitä kotona pärjäämiseen.',
        ],
      },
      {
        category: 'Peseytyminen, hygieniasta huolehtiminen ja pukeutuminen',
        question:
          'Onko muistisairaalla vaikeuksia pitää huolta kodin siisteydestä (mm. yleinen siisteys, jääkaapin hygienia ja elintarvikkeiden käyttökelpoisuus)?',
        positive: [
          'Ei ole vaikeuksia.',
          'On vaikeuksia, mutta kodin siisteydestä pystytään huolehtimaan hänen puolestaan tai siihen on riittävät tukipalvelut.',
        ],
        negative: [
          'On vaikeuksia, eikä kodin siisteydestä pystytä huolehtimaan hänen puolestaan, eivätkä tukipalvelut ole nykyisellään riittävät.',
        ],
      },
    ],
  },
  {
    title: 'Sosiaaliset suhteet',
    slug: 'sosiaaliset-suhteet',
    items: [
      {
        category: 'Yhteydenotot omaisiin',
        question:
          'Onko muistisairaan sosiaalisissa suhteissa (esim. yhteydenpito läheisiin ja ystäviin) tapahtunut muutosta (lisääntyneet tai vähentyneet)? ',
        positive: [
          'Ei ole tapahtunut muutosta tai niiden sävy ei ole huolta herättävä.',
        ],
        negative: [
          'On, hän on aiempaan verrattuna vetäytyneempi ja eristäytyneempi sosiaalisista suhteista.',
          'On, hänen yhteydenottonsa läheisiin ovat lisääntyneet ja niiden sävy aiheuttaa huolta (viittaa esim. yksinäisyyteen, ahdistuneisuuteen tai pelkotiloihin).',
        ],
      },
      {
        category: 'Yhteydenotot omaisiin',
        question: 'Kokeeko muistisairas itsensä yksinäiseksi?',
        positive: ['Ei koe.'],
        negative: ['Hän on ilmaissut tuntevansa itsensä yksinäiseksi.'],
      },
      {
        category: 'Kiinnostus ulkopuoliseen maailmaan',
        question:
          'Onko muistisairaan kiinnostuksessa kodin ulkopuoliseen maailmaan tapahtunut muutosta (esim. lehtien lukeminen, uutisten seuraaminen)?',
        positive: ['Ei ole.'],
        neutral: [
          'On, hän ei seuraa kodin ulkopuolisen maailman tapahtumia entiseen tapaan.',
        ],
      },
    ],
  },
  {
    title: 'Turvallisuus',
    slug: 'turvallisuus',
    items: [
      {
        category: 'Oma ja muiden turvallisuus',
        question:
          'Vaarantaako muistisairas toiminnallaan paloturvallisuuden (esim. lieden päälle jääminen, kynttilöiden varomaton käyttö)?',
        positive: ['Ei vaaranna.'],
        neutral: [
          'Kyllä, hänen toimintansa saattaa vaarantaa paloturvallisuuden, mutta riski on hallinnassa esimerkiksi teknologisilla tukiratkaisuilla.',
        ],
        negative: [
          'Kyllä, hänen toimintansa uhkaa paloturvallisuutta, eikä tilannetta voida korjata teknologisilla tukiratkaisuilla.',
        ],
      },
      {
        category: 'Oma ja muiden turvallisuus',
        question: 'Hamstraako muistisairas tavaroita?',
        positive: ['Ei, tai asuintilojen tavaramäärä on kohtuullinen.'],
        negative: [
          'Kyllä, ja asumistilojen tavaramäärä on kasvanut niin suureksi, että se uhkaa tukkia asunnon ulostuloväylät.',
        ],
      },
      {
        category: 'Oma ja muiden turvallisuus',
        question: 'Onko poistumisturvallisuus uhattuna?',
        positive: ['Ei ole, hätäpoistuminen onnistuu.'],
        negative: [
          'Kyllä, hän ei osaa poistua asunnosta edes hätätilanteessa (ei esim. osaa avata ovea) tai poistuminen on estetty hänen turvallisuuteensa vedoten.',
        ],
      },
      {
        category: 'Oma ja muiden turvallisuus',
        question: 'Jääkö ulko-ovi tarkoituksettomasti auki?',
        positive: ['Ei jää, tai ulko-oven auki jääminen on hyvin satunnaista.'],
        negative: [
          'Kyllä, ulko-ovi jää toistuvasti auki kotiin tullessa tai sieltä poistuttaessa.',
        ],
      },
      {
        category: 'Oma ja muiden turvallisuus',
        question:
          'Pyrkiikö muistisairas lähtemään kotoa epäjohdonmukaisesti tai eksyykö hän ulos lähtiessään (esim. ei tunnista kotiaan)?',
        positive: [
          'Ei pyri, ja ulkoilu onnistuu itsenäisesti tai yhdessä toisen kanssa.',
        ],
        negative: [
          'Hän pyrkii lähtemään kotoa epäjohdonmukaisesti tai eksyy ulos lähtiessään.',
        ],
      },
      {
        category: 'Tapaturmaherkkyyden lisääntyminen',
        question: 'Onko muistisairaan liikuntakyky heikentynyt aiemmasta?',
        positive: [
          'Ei ole tai vaikka on, hän pystyy liikkumaan hyvin itsenäisesti, avustettuna tai apuvälineiden kanssa.',
        ],
        negative: [
          'Kyllä, hänen liikuntakykynsä on heikentynyt tai hän ei muutoinkaan pysty liikkumaan itsenäisesti, avustettuna eikä nykyisten apuvälineiden kanssa.',
        ],
      },
      {
        category: 'Tapaturmaherkkyyden lisääntyminen',
        question:
          'Onko muistisairaan tasapaino heikentynyt ja kaatuileeko hän? ',
        positive: ['Ei ole, hänen tasapainonsa on hyvä.'],
        negative: [
          'Kyllä, heikentyneen tasapainon vuoksi hänen kaatumisriskinsä on kohonnut tai kaatumisia jo sattunut.',
        ],
      },
      {
        category: 'Tapaturmaherkkyyden lisääntyminen',
        question:
          'Onko läheiselläsi ilmennyt muita tapaturmia tai läheltä piti -tilanteita, jotka johtuvat hänen alentuneesta toimintakyvystään ja/tai asuinmuodon sopimattomuudesta hänen tilanteeseensa',
        positive: [
          'Ei, merkittäviä tapaturmia tai läheltä piti -tilanteita ei ole sattunut.',
        ],
        negative: [
          'Kyllä, tapaturmia tai merkittäviä läheltä piti -tilanteita on jo sattunut.',
        ],
      },
      {
        category: 'Turvaranneke',
        question: 'Onko muistisairaalla turvarannekehälytyksiä?',
        positive: [
          'Hänellä ei ole turvaranneketta, eikä hänellä ole siihen tarvetta.',
          'Hänellä on turvaranneke, mutta hälytysten määrä ei ole lisääntynyt ja niitä on vähän.',
        ],
        neutral: [
          'Hänellä ei ole turvaranneketta, mutta sille olisi tarvetta.',
        ],
        negative: [
          'Hänellä on turvaranneke, mutta hän ei osaa käyttää sitä, tai turvarannekehälytyksiä on paljon tai ne ovat lisääntyneet.',
        ],
      },
      {
        category:
          'Ajokäyttäytymiseen ja ulkona liikkumiseen liittyvä tapaturmariski',
        question:
          'Onko muistisairaan liikennetapaturmariski kasvanut tai onko liikennetapaturmia jo sattunut? ',
        positive: [
          'Hän noudattaa liikennesääntöjä ulkona liikkuessa eikä liikennetapaturmariski ei ole kohonnut.',
        ],
        neutral: ['Liikennetapaturmariski on kohonnut.'],
        negative: ['Liikennetapaturmia on jo sattunut. '],
      },
    ],
  },
  {
    title: 'Terveys',
    slug: 'terveydentila',
    items: [
      {
        category: 'Terveydentila',
        question:
          'Onko muistisairaan terveydentila heikentynyt (mukaan lukien kipu)?',
        positive: [
          'Hänen vointinsa on ennallaan tai muutokset eivät vaikuta hänen arjessa selviytymiseensä.',
        ],
        negative: [
          'Hänen voinnissaan on tapahtunut muutos huonompaan, ja se vaikuttaa hänen arjessa selviytymiseensä.',
        ],
      },
      {
        category: 'Ravitsemustila',
        question:
          'Onko muistisairaalla syömättömyyttä, ruokahaluttomuutta tai laihtumista?',
        positive: ['Ei ole.'],
        negative: [
          'On, hänen ruokahalunsa on heikentynyt tai hän on laihtunut.',
        ],
      },
      {
        category: 'Muutokset mielialassa',
        question: 'Onko muistisairaan mieliala laskenut?',
        positive: [
          'Ei ole, hänen mielialansa on hyvä, tai mielialan laskua on ollut vain ajoittain.',
        ],
        negative: [
          'Kyllä, hänen mielialassaan on havaittavissa muutosta huonompaan tai hän on ollut alakuloinen jo pidempään.',
        ],
      },
      {
        category: 'Muutokset mielialassa',
        question: 'Onko muistisairaalla ahdistuneisuutta?',
        positive: ['Ei, tai jos on, se on vain tilapäistä.'],
        negative: [
          'Kyllä, hänellä ilmenee lisääntyvää ahdistuneisuutta tai sitä on ilmennyt jo pidempään.',
        ],
      },
      {
        category: 'Muutokset mielialassa',
        question: 'Onko muistisairaalla ärtyneisyyttä?',
        positive: ['Ei, tai jos on, se on tilapäistä.'],
        neutral: [
          'Kyllä, hänellä on ollut lisääntyvää ärtyneisyyttä tai sitä on ilmennyt jo pidempään.',
        ],
      },
      {
        category: 'Muutokset mielialassa',
        question: 'Onko muistisairaan persoonallisuus muuttunut?',
        positive: ['Ei ole.'],
        neutral: [
          'Kyllä ja se vaikuttaa jo jonkin verran hänen arjessa pärjäämiseensä.',
        ],
        negative: [
          'Kyllä ja se vaikuttaa merkittävissä määrin hänen arjessa pärjäämiseensä.',
        ],
      },
      {
        category: 'Lääkkeiden otto',
        question:
          'Jättääkö muistisairas ottamatta lääkkeitä tai kieltäytyykö hän niistä?',
        positive: ['Ei, hän ottaa lääkkeensä pääsääntöisesti ohjeiden mukaan.'],
        negative: ['Kyllä, toistuvasti.'],
      },
      {
        category: 'Lääkkeiden otto',
        question: 'Piilotteleeko hän lääkkeitä?',
        positive: ['Ei piilottele.'],
        negative: ['Kyllä piilottelee.'],
      },
      {
        category: 'Lääkkeiden otto',
        question:
          'Käyttääkö hän lääkkeitä ohjeiden vastaisesti (esim. ottaa liikaa lääkkeitä)?',
        positive: ['Ei, hän käyttää lääkkeitään ohjeiden mukaisesti.'],
        neutral: ['Kyllä, mutta kyse on ollut yksittäisestä vahingosta.'],
        negative: ['Kyllä, toistuvasti.'],
      },
      {
        category: 'Alkoholin käyttö',
        question:
          'Onko muistisairaan alkoholinkäyttö käyttö lisääntynyt aiemmasta?',
        positive: ['Ei ja se on korkeintaan kohtuullista.'],
        negative: ['Kyllä, se on runsasta tai muuten herättää huolta.'],
      },
      {
        category: 'Terveydenhuollon palveluiden käyttö',
        question:
          'Ovatko hoitojaksot sairaalassa, terveyskeskuksessa tai muissa hoitolaitoksissa lisääntyneet?',
        positive: [
          'Ei, ne ovat vähäisiä tai niissä ei ole tapahtunut merkittävää muutosta.',
        ],
        negative: [
          'Kyllä, määrä on lisääntynyt selvästi aiemmasta tai niiden määrä on jo ennalta runsasta.',
        ],
      },
      {
        category: 'Terveydenhuollon palveluiden käyttö',
        question:
          'Ovatko muistisairaan päivystys- tai ensiapukäynnit lisääntyneet?',
        positive: ['Ei, tai määrä on vähäinen.'],
        negative: [
          'Kyllä, hänen päivystys- tai ensiapukäyntiensä määrä on lisääntynyt selvästi aiemmasta tai niiden määrä on jo ennalta runsasta.',
        ],
      },
      {
        category: 'Terveydenhuollon palveluiden käyttö',
        question:
          'Ovatko muistisairasta koskevat yhteydenotot (muistisairaan itse tai muiden tekemät yhteydenotot) sosiaali- ja terveydenhuoltoon lisääntyneet (hätäkeskus, päivystävät puhelimet, terveysasemien/-keskusten puhelinneuvonta tai ajanvaraus)?',
        positive: ['Ei, tai ne ovat vähäisiä.'],
        negative: [
          'Kyllä, ne ovat lisääntyneet selvästi aiemmasta tai niiden määrä on jo ennalta runsasta.',
        ],
      },
    ],
  },
  {
    title: 'Muisti ja kommunikointi',
    slug: 'muisti',
    items: [
      {
        category: 'Kommunikointi',
        question:
          'Onko muistisairaan puheen tuottaminen tai ymmärtäminen vaikeutunut aiemmasta?',
        positive: [
          'Ei ole tai jo aiemmin esiintyneet kommunikaatiovaikeudet eivät aiheuta huolta muistisairaan arjessa pärjäämisessä.',
        ],
        neutral: [
          'Kyllä on tai aiemmin jo esiintyneet kommunikaatiovaikeudet vaikeuttavat kotona pärjäämistä.',
        ],
        negative: [
          'Kommunikaatiovaikeudet tekevät kotona asumisesta mahdotonta.',
        ],
      },
      {
        category: 'Kommunikointi',
        question: 'Onko muistisairaan kuulo alentunut merkittävästi aiemmasta?',
        positive: [
          'Ei ole tai aiemmin esiintynyt kuulonalenema ei aiheuta huolta hänen asumistilanteessaan.',
        ],
        neutral: [
          'Kyllä on tai jo aiemmin ilmennyt kuulonalenema vaikeuttaa hänen kotona pärjäämistään.',
        ],
      },
      {
        category: 'Kognitiiviset muutokset',
        question:
          'Onko muistisairaan muisti heikentynyt merkittävästi aiemmasta?',
        positive: [
          'Ei ole, tai muistin alenema ei aiheuta huolta hänen asumistilanteessaan.',
        ],
        negative: [
          'Kyllä ja se aiheuttaa huolta hänen kotona pärjäämisestään.',
        ],
      },
      {
        category: 'Kognitiiviset muutokset',
        question:
          'Onko muistisairaalla aiempaa enemmän vaikeuksia ajattelussa, tai pysähtyneisyyttä tai hämmentyneisyyttä?',
        positive: ['Ei ole.'],
        neutral: ['Kyllä on jonkin verran.'],
        negative: [
          'Hänellä on merkittävää pysähtyneisyyttä tai aloitekyvyttömyyttä.',
        ],
      },
      {
        category: 'Kognitiiviset muutokset',
        question:
          'Onko muistisairaalla hahmottamisongelmia (esimerkiksi lattian tai verhojen väärinhahmottamista ja niistä aiheutuvaa pelkoa)? ',
        positive: ['Ei ole. '],
        neutral: [
          'Kyllä, mutta ne ovat lieviä eivätkä vaikeuta hänen kotona pärjäämistään.',
        ],
        negative: [
          'Kyllä, ja ne aiheuttavat hänelle pelkotiloja tai vaaratilanteita kotona liikkuessa.',
        ],
      },
    ],
  },
  {
    title: 'Käyttäytymisen muutokset',
    slug: 'kayttaytymisen-muutokset',
    items: [
      {
        category: 'Epäluuloisuus ja harhaisuus',
        question:
          'Piilotteleeko muistisairas aiempaa enemmän tavaroita (esim. rahaa)?',
        positive: ['Ei piilottele tai se on satunnaista.'],
        neutral: [
          'Kyllä piilottelee tai sitä on ilmennyt runsaasti jo aiemmin.',
        ],
      },
      {
        category: 'Epäluuloisuus ja harhaisuus',
        question:
          'Esittääkö muistisairas aiempaa enemmän perättömiä varkaussyytöksiä?',
        positive: ['Ei esitä tai ne ovat satunnaisia.'],
        negative: [
          'Kyllä esittää tai hän on esittänyt niitä runsaasti jo aiemmin.',
        ],
      },
      {
        category: 'Epäluuloisuus ja harhaisuus',
        question:
          'Onko muistisairaalla harhoja (esim. kuulee tai näkee olemattomia)?',
        positive: ['Ei ole.'],
        neutral: ['Kyllä, mutta se on satunnaista, ohimenevää tai lievää. '],
        negative: [
          'Kyllä ja niitä esiintyy toistuvasti tai merkittävissä määrin.',
        ],
      },
      {
        category: 'Aggressiivisuus ja väkivaltaisuus',
        question:
          'Onko muistisairas käyttäytynyt fyysisesti aggressiivisesti (esim. lyönyt, sylkenyt, potkinut)?',
        positive: ['Ei ole.'],
        negative: [
          'Kyllä, fyysinen aggressiivisuus on lisääntynyt tai sitä on esiintynyt jo aiemmin.  ',
        ],
      },
      {
        category: 'Aggressiivisuus ja väkivaltaisuus',
        question:
          'Onko muistisairas sanallisesti aggressiivinen (esim. kiihtyneisyys, sanallinen uhkailu)?',
        positive: ['Ei ole tai se on satunnaista.'],
        negative: [
          'Kyllä, sanallinen aggressiivisuus on lisääntynyt tai sitä on esiintynyt jo aiemmin.',
        ],
      },
      {
        category: 'Aggressiivisuus ja väkivaltaisuus',
        question: 'Vahingoittaako muistisairas itseään?',
        positive: ['Ei.'],
        negative: ['Kyllä.'],
      },
      {
        category: 'Turvattomuuden tunne',
        question: 'Tuoko muistisairas itse esille turvattomuuden tunnetta?',
        positive: ['Ei tuo.'],
        negative: ['Kyllä, hän kertoo turvattomuuden tunteesta.'],
      },
      {
        category: 'Turvattomuuden tunne',
        question:
          'Onko muistisairaan tukeutuminen toisiin ihmisiin epätyypillistä tai onko se lisääntynyt?',
        positive: ['Ei ole.'],
        negative: ['Kyllä, se on epätyypillistä tai lisääntynyt aiemmasta. '],
      },
      {
        category: 'Turvattomuuden tunne',
        question: 'Esiintyykö muistisairaalla pelkotiloja?',
        positive: ['Ei esiinny.'],
        negative: ['Kyllä esiintyy.'],
      },
      {
        category: 'Muut käytösoireet',
        question:
          'Onko muistisairaan käyttäytyminen muuttunut tavalla, jonka ympäristö kokee haasteelliseksi? (esim. huutelu, estottomuus)?',
        positive: ['Ei ole.'],
        neutral: ['Kyllä, mutta käyttäytymisen muutokset ovat lieviä.  '],
        negative: ['Kyllä ja muutokset ovat merkittäviä.'],
      },
      {
        category: 'Muut käytösoireet',
        question:
          'Vaihtelevatko muistisairaan tunnetilat hänelle epätyypillisesti tai aiemmasta poikkeavasti?',
        positive: ['Eivät vaihtele.'],
        neutral: ['Kyllä vaihtelevat.'],
      },
      {
        category: 'Muut käytösoireet',
        question:
          'Liikkuuko, liikehtiikö tai vaelteleeko muistisairas levottomasti?',
        positive: ['Ei.'],
        neutral: ['Kyllä. '],
      },
    ],
  },
];

export default APP_DATA;
