import React from 'react';
import InstructionsLayout from './InstructionsLayout';
import pic1 from './images/ohje-kuinka-tulostan-ja-tallennan-1.png';
import pic2 from './images/ohje-kuinka-tulostan-ja-tallennan-2.jpg';
import pic3 from './images/ohje-kuinka-tulostan-ja-tallennan-3.jpg';
import styles from './Instruction1.module.scss';

const Instruction1 = () => {
  return (
    <InstructionsLayout title='Kuinka tulostan ja tallennan työkaluun täyttämäni tiedot ja raportin?'>
      <h1
        style={{ fontSize: '1.5rem', lineHeight: '1.9rem', fontWeight: 'bold' }}
      >
        <div style={{ fontSize: '1.3rem', marginBottom: '0.5rem' }}>
          Muistava-työkalun ohje:
        </div>
        KUINKA TULOSTAN JA TALLENNAN TYÖKALUUN TÄYTTÄMÄNI TIEDOT JA RAPORTIN?
      </h1>

      <h3>
        1 Muistisairaan oman tahdon ilmaisun lomakkeen tulostaminen ja
        tallentaminen
      </h3>
      <p>
        Täytettyäsi oman tahdon ilmaisun -lomakkeen voit tulostaa lomakkeen
        talteen sivun alareunassa olevasta ”Tulosta”-painikkeesta. Lomakkeen
        tallentaminen esimerkiksi omalle koneelle tai muistitikulle on myös
        mahdollista ”Tulosta”-painiketta käyttämällä.
      </p>
      <img src={pic1} alt='Ohjekuva 1' className={styles.instructionImg} />
      <ul>
        <li>
          <strong>Haluan tulostaa lomakkeen: </strong>
          <br />
          valitse ”tulosta” → valitse tulostin ja tulosta
        </li>
      </ul>
      <ul>
        <li>
          <strong>Haluan tallentaa lomakkeen tietokoneelle:</strong>
          <br />
          valitse ”tulosta” → valitse tulostinvalikosta ”Tallenna PDF-muodossa”
          tai ”Microsoft Print to PDF” → valitse ”tallenna” → valitse haluamasi
          tallennuspaikka
        </li>
      </ul>

      <h3>
        2 Asumistilanteen arviointityökalun raportin tulostaminen ja
        tallentaminen
      </h3>
      <p>
        Työkalu koostaa tulostettavan / tallennettavan raportin mahdollisesta
        puheeksi oton tarpeesta ja huolenaiheista muistisairautta sairastavan
        asumistilanteeseen liittyen (keltaiset ja punaiset vastaukset). Saat
        raportin auki päänäkymän alareunan <strong>”Katso raportti”</strong>{' '}
        -painikkeesta.
      </p>

      <img src={pic2} alt='Ohjekuva 2' className={styles.instructionImg} />

      <ul>
        <li>
          <strong>Haluan tallentaa lomakkeen tietokoneelle:</strong>
          <br />
          valitse ”tulosta” → valitse tulostinvalikosta ”Tallenna PDF-muodossa”
          tai ”Microsoft Print to PDF” → valitse ”tallenna” → valitse haluamasi
          tallennuspaikka
        </li>
      </ul>

      <img src={pic3} alt='Ohjekuva 3' className={styles.instructionImg} />

      <p>
        <strong>HUOM!</strong> Raportti ja kysymysten vastaukset eivät tallennu
        sivustolle, joten vastaamista ei ole mahdollista jatkaa myöhemmin
        uudelleen. Tulosta / tallenna raportti siis heti vastaamisen jälkeen
        tarvittaessa itsellesi talteen.
      </p>
    </InstructionsLayout>
  );
};

export default Instruction1;
