import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import FooterNavi from '../../components/FooterNavi/FooterNavi';
import {
  setUserProfessional,
  setPatientLivesAlone,
} from '../../actions/preinfoActions';
import { setQuestionsData } from '../../actions/questionActions';

import './PreInfo.scss';

const OutlinedButton = ({ children, action, active }) => {
  return (
    <Button
      variant='outlined'
      className={active ? 'active' : 'inactive'}
      onClick={action}
    >
      {children}
    </Button>
  );
};

const PreInfo = ({ preInfo: { userIsProfessional, patientLivesAlone } }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setQuestionsContinue = () => {
    let action;
    if (
      !userIsProfessional &&
      userIsProfessional !== undefined &&
      patientLivesAlone
    ) {
      action = 'close-notcaring';
    } else if (
      !userIsProfessional &&
      userIsProfessional !== undefined &&
      !patientLivesAlone &&
      patientLivesAlone !== undefined
    ) {
      action = 'close-caring';
    } else if (userIsProfessional && patientLivesAlone) {
      action = 'professional-notcaring';
    } else if (
      userIsProfessional &&
      !patientLivesAlone &&
      patientLivesAlone !== undefined
    ) {
      action = 'professional-caring';
    }
    if (action) {
      dispatch(setQuestionsData(action));
      history.push('/main');
    }
  };

  return (
    <Layout>
      <Section variant='blue' topMargin='0'>
        <div style={{ paddingBottom: 30 }}>
          <a href='/'>Etusivu</a> {'>'} Muistisairaan asumistilanteen arviointi
        </div>
        <Section>
          <div
            style={{ fontWeight: 500, fontSize: '1.3em' }}
            id='fill-information-text'
          >
            Täytä ensin hieman esitietoja.
          </div>
        </Section>
        <Section>
          <div style={{ fontSize: '1.3em' }}>Olen</div>
          <div className='buttonHolder'>
            <OutlinedButton
              active={
                !userIsProfessional && userIsProfessional !== undefined && true
              }
              action={() => dispatch(setUserProfessional(false))}
            >
              Omainen / Läheinen
            </OutlinedButton>
            <div className='buttonMargin' />
            <OutlinedButton
              active={userIsProfessional && true}
              action={() => dispatch(setUserProfessional(true))}
            >
              Ammattilainen
            </OutlinedButton>
          </div>
        </Section>
        <Section>
          <div style={{ fontSize: '1.3em' }}>Asuuko muistisairas yksin?</div>
          <div className='buttonHolder'>
            <OutlinedButton
              active={patientLivesAlone && true}
              action={() => dispatch(setPatientLivesAlone(true))}
            >
              Kyllä
            </OutlinedButton>
            <div className='buttonMargin' />
            <OutlinedButton
              active={
                !patientLivesAlone && patientLivesAlone !== undefined && true
              }
              action={() => dispatch(setPatientLivesAlone(false))}
            >
              Ei *
            </OutlinedButton>
          </div>
          <div>
            *) Valitse tämä vaihtoehto myös siinä tapauksessa, jos muistisairas
            asuu yksin, mutta läheinen käy hänen luonaan ja huolehtii hänestä
            päivittäin
          </div>
        </Section>

        <FooterNavi
          prevlink='/'
          prevlinkText='Takaisin alkuun'
          nextlink={
            userIsProfessional !== undefined &&
            patientLivesAlone !== undefined ? (
              <Button
                className='submit'
                variant='outlined'
                onClick={() =>
                  setQuestionsContinue(userIsProfessional, patientLivesAlone)
                }
              >
                Jatka
              </Button>
            ) : null
          }
        />
      </Section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  preInfo: state.preinfo,
});

export default connect(mapStateToProps, {
  setUserProfessional,
  setPatientLivesAlone,
  setQuestionsData,
})(PreInfo);
