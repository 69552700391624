import React from 'react';
import InstructionsLayout from './InstructionsLayout';
import pic1 from './images/ohje-asumistilanteen-arviointityokalun-kayttoon-1.jpg';
import pic2 from './images/ohje-asumistilanteen-arviointityokalun-kayttoon-2.jpg';
import pic3 from './images/ohje-asumistilanteen-arviointityokalun-kayttoon-3.jpg';
import pic4 from './images/ohje-asumistilanteen-arviointityokalun-kayttoon-4.jpg';
import styles from './Instruction1.module.scss';

const Instruction1 = () => {
  return (
    <InstructionsLayout title='Näin käytät muistisairautta sairastavan asumistilanteen arviointityökalua'>
      <h1
        style={{ fontSize: '1.5rem', lineHeight: '1.9rem', fontWeight: 'bold' }}
      >
        <div style={{ fontSize: '1.3rem', marginBottom: '0.5rem' }}>
          Muistava-työkalun ohje:
        </div>
        NÄIN KÄYTÄT MUISTISAIRAUTTA SAIRASTAVAN ASUMISTILANTEEN
        ARVIOINTITYÖKALUA
      </h1>

      <h3>1. Yleistä työkalun käyttämisestä</h3>
      <p>
        <strong>Muistisairaan asumistilanteen arviointityökalu</strong> on
        tarkoitettu ohjaavaksi työkaluksi muistisairautta sairastavan henkilön
        omaisille, läheisille ja ammattilaisille. Työkalun avulla voidaan
        tarkastella omassa kodissaan (ei ympärivuorokautisen hoivan piirissä)
        asuvan muistisairautta sairastavan asumistilannetta, sen puheeksi oton
        ja tarkemman arvioinnin tarvetta yhdeksän osa-alueen ja niiden
        sisältämien kysymysten kautta. Työkalua ei ole tarkoitettu käytettäväksi
        suosituksena siitä, milloin muistisairautta sairastavan henkilön tulisi
        muuttaa kotoaan, vaan sen on tarkoitus toimia asumiseen liittyvien
        kysymysten puheeksi oton ja sen tarpeen tunnistamisen tukena.
        <br />
        <br />
        Työkalu sisältää yhdeksän osa-aluetta ja niihin liittyviä kysymyksiä
        valmiine vastausvaihtoehtoineen. Työkalun täyttämiseen kuluu aikaa
        keskimäärin noin tunnin verran, mikäli tarkoituksena on vastata työkalun
        kaikkiin kysymyksiin (yhdeksän osa-aluetta, sisältää yhteensä 59–73
        kysymystä). Työkalun yhdeksästä osa-alueesta voi kuitenkin myös valita
        vastattavaksi vain ne osa-alueet, jotka kulloinkin tuntuvat
        ajankohtaisimmilta. Työkalun osa-alueet ovat:
        <br />
        <br />
        Muistisairautta sairastavan:
        <li>asuinympäristö</li>
        <li>päivittäiset askareet</li>
        <li>sosiaaliset suhteet</li>
        <li>turvallisuus</li>
        <li>terveys</li>
        <li>muisti ja kommunikointi</li>
        <li>käyttäytymisen muutokset</li>
        <br />
        Läheisen:
        <li>terveys ja toimintakyky</li>
        <li>jaksaminen</li>
        <br />
        Työkalun voi täyttää tietokoneella, tabletilla tai kännykällä.
        <br />
        <br />
        Työkalu koostaa vastausten perusteella raportin mahdollisesta
        asumistilanteen puheeksi oton tarpeesta ja siihen liittyvistä
        huolenaiheista. Raportin tulokset ovat suuntaa antavia ja muistisairaan
        tilanne tulee aina arvioida yksilöllisesti kokonaisuutena. Raportin
        mahdollisesti esille nostamat ja huomiota vaativat kohdat on hyvä ottaa
        puheeksi läheisten ja ammattilaisten kanssa sekä arvioida tarkemmin
        yhdessä niiltä osin asumistilannetta ja sen mahdollisia muutostarpeita.
        Muistisairaan asumistilannetta tulee myös seurata jatkossa säännöllisin
        väliajoin.
        <br />
        <br />
        Työkaluun täytetyt vastaukset eivät tallennu palveluun, vaan ne häviävät
        internet-selaimen sulkemisen jälkeen. Vastaukset nollaantuvat myös, jos
        palaa muistava.fi -sivuston etusivulle ja aloittaa työkalujen
        täyttämisen alusta. Työkalun koostama raportti kannattaa siten tulostaa
        tai tallentaa talteen heti työkalun täyttämisen jälkeen ennen
        internet-selaimen sulkemista tai paluuta aloitussivulle (ks. kohta 2.3
        Tulosten yhteenveto ja raportin tulostaminen/tallentaminen).
      </p>
      <p>&nbsp;</p>

      <h3>2. Ohjeita työkalun käyttämiseen</h3>
      <h4>2.1 Esitiedot</h4>
      <p>
        Aloita työkalun käyttäminen täyttämällä esitiedot. Valitse ensin, onko
        arviointityökalun täyttäjä <strong>omainen/läheinen</strong> tai{' '}
        <strong>ammattilainen</strong>.
        <br />
        <br />
        Tämän jälkeen valittavanasi on, asuuko muistisairas yksin vai ei.
        Valitse ”kyllä” vaihtoehto, jos muistisairas asuu yksin. Mikäli
        muistisairas asuu yhdessä läheisen kanssa, valitse ”ei”. Valitse
        vaihtoehto ”ei” myös siinä tapauksessa, mikäli muistisairas asuu yksin,
        mutta läheinen (esim. omainen, naapuri) käy hänen luonaan ja huolehtii
        hänestä päivittäin.
        <br />
        <br />
        Kun olet täyttänyt esitiedot, paina <strong>”jatka”</strong>.
        <br />
        <br />
        HUOM! Voit muuttaa esitietoja tarvittaessa ”Muuta esitietoja”
        -painikkeesta arviointityökalun oikeasta ylälaidasta myös myöhemmin.
        Tällöin aiemmat valinnat häviävät. <br />
      </p>
      <h4>2.2. Arviointityökalun osa-alueiden täyttäminen</h4>
      <p>
        Aloita työkalun täyttäminen valitsemalla haluamasi osa-alue painamalla
        sitä koskevaa kuvaketta. Pääset tällöin täyttämään osa-aluetta koskevia
        kysymyksiä. Valitse kysymysten valmiista vastausvaihtoehdoista
        muistisairaan tilannetta tai omaa tilannettasi läheisenä parhaiten
        kuvaava vaihtoehto ja paina ”jatka”. <br />
        <br />
        Täyttäessäsi työkalua sinut ohjataan osa-alueen päättymisen jälkeen
        täyttämään aina seuraavaa osa-aluetta. Mikäli et koe kaikkien
        osa-alueiden täyttämistä tarpeellisena tai haluat täyttää ne eri
        järjestyksessä, voit palata päänäkymään ”Takaisin
        päänäkymään”-painikkeesta. <br />
        <br />
        HUOM! Jos haluat täyttää työkalun useammassa osassa, muista tulostaa tai
        tallentaa raportti jokaisen kerran jälkeen (tiedot eivät tallennu
        itsestään arviointityökaluun).
        <br />
        <br />
        Voit kuitenkin muokata vastauksiasi palaamalla takaisin kyseiseen
        osa-alueeseen ja sen kysymyksiin niin kauan kunnes suljet selaimen tai
        palaat kokonaan alkuun.
        <br />
      </p>
      <img src={pic1} alt='Ohjekuva 1' className={styles.instructionImg} />
      <img src={pic2} alt='Ohjekuva 2' className={styles.instructionImg} />

      <h4>2.3 Tulosten yhteenveto ja raportin tulostaminen/tallentaminen</h4>
      <p>
        Vastattuasi kysymyksiin siirryt takaisin päänäkymään, josta näet eri
        osa-alueita koskevat kriittisyysarviot värikoodattuina:
        <br />
        <br />
        <li>
          Sinivihreä = osa-alueessa ei huolta herättävää (edellyttää, että
          kaikkiin osa-alueen kysymyksiin on vastattu vihreällä
          vastausvaihtoehdolla)
        </li>
        <li>
          Keltainen = osa-alueessa jokin vastauksista / jotkin vastaukset huolta
          herättäviä, asuminen hyvä ottaa puheeksi
        </li>
        <li>
          Punainen = osa-alueessa jokin vastauksista / jotkin vastaukset
          kriittisiä, asuminen tulee ottaa pian puheeksi
        </li>
        <br />
        Työkalu koostaa myös tulostettavan / tallennettavan raportin
        mahdollisesta puheeksi oton tarpeesta ja huolenaiheista muistisairautta
        sairastavan asumistilanteeseen liittyen (keltaiset ja punaiset
        vastaukset). Saat raportin auki päänäkymän alareunan{' '}
        <strong>”Katso raportti”</strong>
        -painikkeesta.
        <img src={pic3} alt='Ohjekuva 3' className={styles.instructionImg} />
        Voit halutessasi tulostaa tai tallentaa raportin koneellesi sivun
        oikeasta yläreunasta löytyvästä <strong>
          ”Tulosta”
        </strong>-painikkeesta. <br />
        <br />
        <li>
          <strong>Tulosta</strong>: valitse ”tulosta” → valitse tulostin ja
          tulosta
        </li>
        <li>
          <strong>Tallentaminen tietokoneelle</strong>: valitse ”tulosta” →
          valitse tulostinvalikosta ”Tallenna PDF-muodossa” tai ”Microsoft Print
          to PDF” → Tallenna → valitse haluamasi tallennuspaikka.
        </li>
        <br />
        <strong>HUOM!</strong> Raportti ja kysymysten vastaukset eivät tallennu
        sivustolle, joten vastaamista ei ole mahdollista jatkaa myöhemmin
        uudelleen. Tulosta / tallenna raportti siis heti vastaamisen jälkeen
        tarvittaessa itsellesi talteen.
      </p>

      <img src={pic4} alt='Ohjekuva 4' className={styles.instructionImg} />

      <h4>4. Lisätietoja</h4>
      <p>
        Työkalujen käyttö on maksutonta. Työkaluihin täytettävät tiedot eivät
        tallennu eikä niistä muodostu rekisteriä, vaan ne häviävät
        internet-selaimen sulkemisen jälkeen.
        <br />
        <br />
        Työkalu on kehitetty Hoitotyön tutkimussäätiön (Hotus) ja Muistiliiton
        yhteisessä STEA-rahoitteisessa Muistava-hankkeessa (2018–2020).
        Lisätietoja Muistava-hankkeesta ja -työkaluista:{' '}
        <a href='mailto:hotus@hotus.fi'>hotus@hotus.fi</a>. Muistava-työkalujen
        digitaalisen palvelun tekninen toteutus on toteutettu yhteistyössä
        Skholen kanssa.
        <br />
        <br />
        <strong>HUOM!</strong> Työkalu on tarkoitettu ohjaavaksi työkaluksi
        tukemaan muistisairautta sairastavan henkilön asumistilanteen puheeksi
        ottoa niin perheen keskinäisissä keskusteluissa kuin keskusteluissa
        sosiaali- ja terveydenhuollon ammattilaisten kanssa. Työkalu ei ole
        tarkoitettu käytettäväksi suosituksena siitä, milloin muistisairautta
        sairastavan henkilön tulisi muuttaa kotoaan. Kunkin muistisairaan
        tilanne tulee aina arvioida yksilöllisesti myös muilta osin kuin mitä
        työkalussa on tarkasteltu. Muistisairautta sairastavan asumistilannetta
        tulee myös seurata säännöllisin väliajoin.
      </p>
    </InstructionsLayout>
  );
};

export default Instruction1;
