import React from 'react';

import Section from '../../components/Section/Section';

import Layout from '../../components/Layout/Layout';
import FooterNavi from '../../components/FooterNavi/FooterNavi';
import './ServiceInfo.scss';

const ServiceInfo = () => {
  return (
    <Layout title='Tietoa Muistava-työkaluista'>
      <Section variant='blue' maxWidth='md' topMargin='0'>
        <div style={{ paddingBottom: 30 }}>
          <a href='/'>Etusivu</a> > Tietoa Muistava-työkaluista
        </div>
        <div className='section--wrapper white--wrapper'>
          <p>
            Muistava-työkalut on kehitetty Hoitotyön tutkimussäätiön (Hotus) ja
            Muistiliiton yhteisessä Sosiaali- ja terveysjärjestöjen
            avustuskeskuksen rahoittamassa Muistava-hankkeessa (2018–2020).
            Työkalut laadittiin yhdessä muistisairautta sairastavien, omaisten,
            läheisten sekä kolmannella sektorilla ja sosiaali- ja
            terveydenhuollossa toimivien ammattilaisten ja asiantuntijoiden
            kanssa. Työkalut on tarkoitettu tukemaan muistisairautta sairastavan
            henkilön asumistilanteen oikea-aikaista puheeksi ottoa, asumisen
            suunnittelua ja siten turvallista ja muistisairaan tarpeita
            vastaavaa asumista. Työkalujen käyttö on maksutonta. Täytetyt
            vastaukset eivät tallennu palveluun, vaan ne häviävät
            internet-selaimen sulkemisen jälkeen.
          </p>

          <h2 style={{ marginBottom: 0 }}>
            Lomake muistisairaan oman tahdon ilmaisuun
          </h2>
          <p>
            Tämä työkalu on tarkoitettu muistisairautta sairastaville. Tällä
            lomakkeella muistisairautta sairastava voi kirjata itsenäisesti tai
            avustettuna omia toiveitaan tulevaa asumistaan koskien. Lomakkeen
            voi täyttämisen jälkeen tulostaa talteen.
            <br />
            <a href='ohje-kuinka-tulostan-ja-tallennan' title='Tulostusohje'>
              Katso tulostusta koskeva ohje
            </a>
            <br />
            <a
              href='/tulostusohje.pdf'
              title='Tulostusohje - PDF'
              target='_blank'
            >
              (Vaihtoehtoinen ladattava PDF 0,2Mt).
            </a>
            .<br />
            <br />
            Lomakkeeseen kirjatuista toiveista on hyvä keskustella läheisten
            kanssa, jotta he ovat niistä tietoisia. Toiveiden toteutumista ei
            voida taata, mutta niistä keskustelu varhaisessa vaiheessa
            mahdollistaa muistisairaan oman äänen kuulluksi tulemisen tulevissa
            asumista koskevissa päätöksissä.
          </p>

          <h2 style={{ marginBottom: 0 }}>Muistava-kuvakortit</h2>
          <p>
            Tämä työkalu on tarkoitettu muistisairautta sairastavalle, hänen
            omaisilleen, läheisilleen ja ammattilaisille. Kuvakortit on
            suunniteltu tukemaan keskustelua nykyisestä asumistilanteesta ja
            asumiseen liittyvistä tulevaisuuden suunnitelmista. Kaikkia kortteja
            ei tarvitse käydä läpi yhdellä kertaa.
            <br />
            <br />
            Kuvakortit käsittelevät seuraavia aiheita:
          </p>

          <ul>
            <li>Koti ja asuinympäristö</li>
            <li>Arjen sujuminen</li>
            <li>Sosiaaliset suhteet</li>
            <li>Terveydentila</li>
            <li>Turvallisuus</li>
            <li>Kommunikointi muiden kanssa</li>
            <li>Käyttäytymisen muutokset</li>
            <li>Omaiset ja läheiset</li>
          </ul>
          <p>
            Kuvakortteja voidaan käyttää digitaalisessa muodossa tietokoneella,
            tablettitietokoneella tai älypuhelimella, tai ne voi tulostaa omaan
            käyttöönsä.
          </p>

          <h2 style={{ marginBottom: 0 }}>
            Työkalu muistisairaan asumistilanteen arviointiin
          </h2>
          <p>
            Tämä työkalu on tarkoitettu ohjaavaksi työkaluksi muistisairautta
            sairastavien henkilöiden omaisille, läheisille ja muistisairaiden
            parissa työskenteleville ammattilaisille arvioitaessa omassa
            kodissaan (ei ympärivuorokautisen hoivan piirissä) asuvan
            muistisairaan asumistilanteen puheeksi oton ja tarkemman arvioinnin
            tarvetta. Työkalua ei ole tarkoitettu käytettäväksi suosituksena
            siitä, milloin muistisairautta sairastavan henkilön tulisi muuttaa
            kotoaan.
            <br />
            <br />
            Työkalun avulla muistisairautta sairastavan henkilön
            asumistilannetta voidaan tarkastella yhdeksän osa-alueen ja niiden
            sisältämien kysymysten kautta:
          </p>
          <p style={{ marginBottom: 0 }}>Muistisairautta sairastavan</p>
          <ul style={{ marginTop: 0 }}>
            <li>Asumisympäristö</li>
            <li>Päivittäiset askareet</li>
            <li>Sosiaaliset suhteet</li>
            <li>Turvallisuus</li>
            <li>Terveydentila ja terveydestä huolehtiminen</li>
            <li>Muisti ja kommunikointi</li>
            <li>Käyttäytymisen muutokset</li>
          </ul>

          <p style={{ marginBottom: 0 }}>
            Läheisen (mikäli muistisairas asuu yhdessä läheisen kanssa tai
            läheinen huolehtii hänestä muutoin)
          </p>
          <ul style={{ marginTop: 0 }}>
            <li>Terveydentila ja toimintakyky</li>
            <li>Jaksaminen</li>
          </ul>

          <p>
            Työkalun kaikkia osia ei tarvitse täyttää kerralla, vaan siitä voi
            täyttää ne osat, joille kokee olevan eniten tarvetta. Valituista
            osista tulee kuitenkin täyttää kaikki kyseistä osa-aluetta koskevat
            kysymykset. Kysymyksiin vastaamisen jälkeen työkalu koostaa raportin
            mahdollisesta puheeksi oton tarpeesta ja huolenaiheista
            muistisairautta sairastavan asumistilanteeseen liittyen. Raportin
            voi halutessaan tulostaa.
            <br />
            <a
              href='/ohje-asumistilanteen-arviointityokalun-kayttoon'
              title='Tarkemmat käyttöohjeet'
            >
              Katso tarkemmat käyttöohjeet tästä
            </a>
            <br />(
            <a
              href='/Kayttoohje_Asumistilanteen-arviointityokalu.pdf'
              target='_blank'
              title='Tarkemmat käyttöohjeet - PDF'
            >
              Vaihtoehtoinen ladattava PDF 0,4Mt
            </a>
            ). .
          </p>

          <h2 style={{ marginBottom: 0 }}>
            Esite Muistava-hankkeesta ja -työkaluista
          </h2>
          <p>
            <a
              href='/Muistava-esite_sahkoinen.pdf'
              target='_blank'
              title='Muistava-esite'
            >
              Muistava-esite (PDF 0,5Mt)
            </a>
          </p>

          <p>
            Haluatko tulostaa tai painattaa Muistava-esitteen?
            <br />
            <a
              href='/Muistava-esite_tulostettava.pdf'
              target='_blank'
              title='Muistava-esite (tulostusversio)'
            >
              Tulostusversio Muistava-esitteestä (PDF 1Mt)
            </a>
            <br />
            <a
              href='/Muistava-esite_painoversio.pdf'
              target='_blank'
              title='Muistava-esite (painoversio'
            >
              Painoversio Muistava-esitteestä (PDF 1Mt)
            </a>
            <br />
            <br />
            <a
              href='https://www.youtube.com/watch?v=fv5_2MDtxLM'
              target='_blank'
              rel='noopener noreferrer'
              title='Muistava-työkalujen esittelyvideo'
            >
              Muistava-työkalujen esittelyvideo (YouTubessa, tekstitetty)
            </a>
          </p>

          <h2 style={{ marginBottom: 0 }}>
            Lisätietoja Muistava-hankkeesta ja -työkaluista
          </h2>
          <p>
            <a href='/saavutettavuusseloste'>Saavutettavuusseloste</a>
            <br />
            <br />
            Yhteydenotot sähköpostitse:{' '}
            <a href='mailto:hotus@hotus.fi'>hotus@hotus.fi</a>
            <br />
            <br />
            Muistava-työkalujen digitaalisen palvelun tekninen toteutus on
            toteutettu yhteistyössä <a href='https://www.skhole.fi'>
              Skholen
            </a>{' '}
            kanssa.
          </p>
        </div>
        <FooterNavi />
      </Section>
    </Layout>
  );
};

export default ServiceInfo;
