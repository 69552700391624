import { SET_FORM_FIELD, CLEAR_FORM_FIELDS } from '../actions/types';

const initialState = {
  field1: '',
  field2: '',
  field3: '',
  field4: '',
  field5: '',
  field6: '',
  field7: '',
  field8: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case CLEAR_FORM_FIELDS:
      return {
        ...state,
        field1: '',
        field2: '',
        field3: '',
        field4: '',
        field5: '',
        field6: '',
        field7: '',
        field8: '',
      };

    default:
      return state;
  }
};
