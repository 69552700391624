import React from 'react';
import Section from '../../components/Section/Section';

import Layout from '../../components/Layout/Layout';
import FooterNavi from '../../components/FooterNavi/FooterNavi';

const NotFound = () => {
  return (
    <Layout title='404 - Sivua ei löydy'>
      <Section variant='blue' maxWidth='md' topMargin='0'>
        <div className='section--wrapper white--wrapper'>
          <p>Etsimääsi sivua ei löytynyt</p>
        </div>
        <FooterNavi />
      </Section>
    </Layout>
  );
};

export default NotFound;
